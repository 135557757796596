import React, { createContext, useContext, useState } from "react";

import { orderModes, OrderModesTypes } from "../../utils/modes";
import { useGetData } from "../../../../hooks/useGetData";
import { ISample } from "../../../Samples/interface";
import { IContainer } from "../../interface";

export const OrderReceiveContext = createContext<any>(null);

const mode: OrderModesTypes = orderModes.receive;
export const OrderReceiveContextProvider = ({ children }) => {
    const [selectedSamples, setSelectedSamples] = useState<ISample[]>([]);
    const [selectedInventoryContainers, setSelectedInventoryContainers] = useState<IContainer[]>([]);
    const [selectedSampleContainers, setSelectedSampleContainers] = useState<IContainer[]>([]);

    const { data, refreshData, handleReload } = useGetData<any>({
        url: `/orders/receive`,
        additionalParams: "&samples=true",
        onUnmount: () => setSelectedSamples([]),
    });

    return (
        <OrderReceiveContext.Provider
            value={{
                selectedSamples,
                setSelectedSamples,
                selectedInventoryContainers,
                setSelectedInventoryContainers,
                mode,
                data,
                refreshData,
                handleReload,
                selectedSampleContainers,
                setSelectedSampleContainers,
            }}
        >
            {children}
        </OrderReceiveContext.Provider>
    );
};

export const useOrderReceive = (): IUseOrderReceive => {
    const {
        selectedSamples,
        setSelectedSamples,
        selectedInventoryContainers,
        setSelectedInventoryContainers,
        mode,
        data,
        refreshData,
        handleReload,
        selectedSampleContainers,
        setSelectedSampleContainers,
    } = useContext(OrderReceiveContext);

    return {
        selectedSamples,
        setSelectedSamples,
        selectedInventoryContainers,
        setSelectedInventoryContainers,
        mode,
        data,
        refreshData,
        handleReload,
        selectedSampleContainers,
        setSelectedSampleContainers,
    };
};

interface IUseOrderReceive {
    selectedSamples: ISample[];
    setSelectedSamples: (arg: ISample[]) => void;
    selectedInventoryContainers: IContainer[];
    setSelectedInventoryContainers: (arg: IContainer[]) => void;
    mode: OrderModesTypes;
    data: any[];
    refreshData: () => void;
    handleReload: Function;
    selectedSampleContainers: IContainer[];
    setSelectedSampleContainers: React.Dispatch<React.SetStateAction<IContainer[]>>;
}

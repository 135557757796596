import React, { createContext, useContext, useRef, useState } from "react";

import { ISample } from "../../ReleaseOrders/interfaces/interfaces";
import { editTabs, EditTabValuesType } from "../utils/edit-tabs";
import { orderModes, OrderModesTypes } from "../../utils/modes";
import { useFetchTableData } from "hooks/useFetchTableData";
import { useFormData } from "hooks/useFormData";
import { IOrder } from "../../interface";

export const OrderEditContext = createContext<any>(null);

const mode: OrderModesTypes = orderModes.edit;

export const OrderEditContextProvider = ({ children }) => {
    const [selectedOrders, setSelectedOrders] = useState<IOrder[]>([]);
    const [selectedSamples, setSelectedSamples] = useState<ISample[]>([]);
    const [selectedTab, setSelectedTab] = useState<EditTabValuesType>(editTabs.samples);

    const refreshOrdersRef = useRef<() => void>();
    const refreshSamplesRef = useRef<() => void>();

    const [isArchiveDatabase, setIsArchiveDatabase] = useState<boolean>(false);

    const samplesObj = useFetchTableData<any>({
        url: selectedOrders.length == 1 ? `/samples` : undefined,
        initParams: "?pageSize=10&page=1",
        triggerValues: [selectedOrders],
        additionalParams: `&orderId=${selectedOrders[0]?.id}`,
        onUnmount: () => setSelectedSamples([]),
    });

    const { formData: allReportTypes } = useFormData<string[], any>({
        url: "/bs/testPlans/test_plan_report_types",
        mapFormData: (data) => data.reportTypes.map((rt) => rt.value),
    });

    return (
        <OrderEditContext.Provider
            value={{
                isArchiveDatabase,
                setIsArchiveDatabase,
                selectedOrders,
                setSelectedOrders,
                selectedSamples,
                setSelectedSamples,
                allReportTypes,
                refreshOrdersRef,
                refreshSamplesRef,
                selectedTab,
                setSelectedTab,
                mode,
                samplesObj,
            }}
        >
            {children}
        </OrderEditContext.Provider>
    );
};

export const useOrderEdit = (): IUseOrderEdit => {
    const {
        isArchiveDatabase,
        setIsArchiveDatabase,
        selectedOrders,
        setSelectedOrders,
        selectedSamples,
        setSelectedSamples,
        allReportTypes,
        refreshOrdersRef,
        refreshSamplesRef,
        selectedTab,
        setSelectedTab,
        mode,
        samplesObj,
    } = useContext(OrderEditContext);

    return {
        isArchiveDatabase,
        setIsArchiveDatabase,
        selectedOrders,
        setSelectedOrders,
        selectedSamples,
        setSelectedSamples,
        allReportTypes,
        refreshOrdersRef,
        refreshSamplesRef,
        selectedTab,
        setSelectedTab,
        mode,
        samplesObj,
    };
};

interface IUseOrderEdit {
    isArchiveDatabase: boolean;
    setIsArchiveDatabase: React.Dispatch<React.SetStateAction<boolean>>;
    selectedOrders: IOrder[];
    setSelectedOrders: (arg: IOrder[]) => void;
    selectedSamples: ISample[];
    setSelectedSamples: (arg: ISample[]) => void;
    allReportTypes: string[] | undefined;
    refreshOrdersRef: React.MutableRefObject<(() => void) | undefined>;
    refreshSamplesRef: React.MutableRefObject<(() => void) | undefined>;
    selectedTab: EditTabValuesType;
    setSelectedTab: (arg: EditTabValuesType) => void;
    mode: "edit";
    samplesObj: {
        data: IData<any>;
        setData: React.Dispatch<React.SetStateAction<IData<any>>>;
        refreshData: () => void;
        handleReload: (params: any, refresh?: any) => void;
        patchRecord: PatchRecordType;
        addRecord: AddRecordType;
    };
}

'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Czech Republic (Template)
var strings = {
  prefixAgo: null,
  prefixFromNow: null,
  suffixAgo: 'ago',
  suffixFromNow: 'from now',
  second: '1 second',
  seconds: '%d seconds',
  minute: 'about a minute',
  minutes: '%d minutes',
  hour: 'about an hour',
  hours: 'about %d hours',
  day: 'a day',
  days: '%d days',
  month: 'about a month',
  months: '%d months',
  year: 'about a year',
  years: '%d years',
  wordSeparator: ' '
};
exports["default"] = strings;
import Axios from "axios";

import { sweetConfirm } from "../components/sweet-alert/sweetConfirm";
import { IReqParams } from "../pages/interfaces/IReqParams";
import addToast from "../utils/addToast";

export const handleDeleteTableData = async (
    url: string,
    dataName: string,
    selectedDataIds: number[],
    refreshData: Function,
    customPluralForm: string | undefined = undefined,
    reqParams: IReqParams | undefined = undefined
) => {
    if (
        !(await sweetConfirm("Delete", {
            text: `Do you want to delete ${customPluralForm ? customPluralForm : `${dataName.toLowerCase()}(s)`}?`,
            count: selectedDataIds.length,
        }))
    )
        return;

    try {
        const response = await Axios.delete(url, { data: { ids: selectedDataIds, reqParams } });
        refreshData();

        const deletedItemForm = customPluralForm || `${dataName}${selectedDataIds.length > 1 ? "s" : ""}`;
        addToast({ title: `${deletedItemForm} has been deleted.`.firstLetterToUpperCase() });

        return response.status;
    } catch (err: any) {
        if (err?.response?.status == "403") return err.response.data;
        return err?.response?.status;
    }
};

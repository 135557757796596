import React, { CSSProperties, useEffect, useRef, useState } from "react";

import { Col, FormGroup, Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Calendar } from "primereact/calendar";
import classnames from "classnames/dedupe";

import { RequiredLabel } from "components/custom-modal-elements/required-label";
import { HideButton } from "components/custom-modal-elements/hide-button";
import { formatDate, formatDateWithFullTime } from "utils/formatDate";
import { LabelError, PrimeSelect } from "..";

import { v4 as uuid } from "uuid";

import "./styles.scss";

interface IDateTimePicker {
    label?: string;
    name?: string;
    noLabel?: boolean;
    labelSm?: number;
    colSm?: number;
    value?: string | string[];
    className?: string;
    disabled?: boolean;
    placeholder?: string;
    showTime?: boolean;
    errors?: string;
    forLabel?: string;
    isHideMode?: boolean;
    required?: boolean;
    style?: CSSProperties;
    yearNavigator?: boolean;
    id?: string;
    minDate?: Date;
    maxDate?: Date;
    selectionMode?: "single" | "multiple" | "range";
    touchUI?: boolean;
    onChange?: (e: { target: { name: string; value: string | (string | undefined)[] | undefined | null } }) => void;
}

export const DateTimePicker = (props: IDateTimePicker) => {
    const {
        label = "",
        noLabel,
        labelSm,
        colSm,
        value,
        className,
        disabled,
        placeholder = "Enter date...",
        showTime,
        errors,
        forLabel,
        required,
        isHideMode,
        name = "",
        id = name,
        style,
        minDate,
        maxDate,
        selectionMode = "single",
        touchUI,
        yearNavigator = false,
        onChange,
    } = props;

    const [isHidden, setIsHidden] = useState<boolean>(false);

    const dummyRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const ref = useRef<any>(null);

    const { t } = useTranslation();

    useEffect(() => {
        if (selectionMode == "multiple" && inputRef.current) inputRef.current.value = (value as string[])?.join(", ") || "";
    }, [value]);

    const formatTime = showTime ? formatDateWithFullTime : formatDate;

    const handleOnChange = async (e) => {
        const { value } = e.target;
        const newValue = {
            range: value,
            single: value,
            multiple: value,
        }[selectionMode];

        const inputValue = Array.isArray(newValue) ? newValue.map((d) => formatTime(d)) : formatTime(newValue) || "";

        onChange &&
            onChange({
                ...e,
                target: {
                    value: {
                        range: !!inputValue.length ? inputValue : null,
                        single: inputValue || newValue,
                        multiple: inputValue || newValue,
                    }[selectionMode],

                    name,
                },
            });
    };

    const selectTemplate = (e) => {
        let { options, value } = e;
        options = options.map((o) => ({ ...o, label: t(o.label) }));

        return (
            <PrimeSelect
                className="prime-calendar-select"
                value={value}
                options={options}
                onChange={(event) => e.onChange(event.originalEvent, event.value)}
                style={{ lineHeight: 1 }}
                showClear={false}
            />
        );
    };

    const preventSubmitOnEnter = (e) => {
        if (e.key == "Enter") {
            //? prevent from submit and fire onClick logic to hide the calender panel
            e.preventDefault();
            e.target.blur();
            dummyRef?.current && dummyRef.current.click();
        }
    };

    return (
        <FormGroup row>
            {noLabel ? null : (
                <Label for={id} sm={labelSm || 3}>
                    {t(label)}:{required && <RequiredLabel />}
                </Label>
            )}
            <div ref={dummyRef} id="dummy"></div>
            <Col sm={colSm} onKeyDown={preventSubmitOnEnter}>
                {isHideMode && !required && <HideButton isHidden={isHidden} setIsHidden={setIsHidden} />}
                <Calendar
                    ref={ref}
                    inputId={id}
                    className={classnames(className, "gt-date-time-picker")}
                    style={style}
                    disabled={disabled}
                    name={name}
                    value={
                        Array.isArray(value)
                            ? (value.map((v) => (v === undefined ? undefined : new Date(v))) as any)
                            : value
                            ? new Date(value)
                            : undefined
                    }
                    placeholder={t(placeholder)}
                    onChange={handleOnChange}
                    yearRange="2020:2030"
                    dateFormat="yy-mm-dd"
                    monthNavigator
                    yearNavigator={yearNavigator}
                    monthNavigatorTemplate={selectTemplate}
                    yearNavigatorTemplate={selectTemplate}
                    showTime={showTime}
                    showSeconds={showTime}
                    showIcon
                    inputRef={inputRef}
                    minDate={minDate}
                    maxDate={maxDate}
                    selectionMode={selectionMode}
                    mask={selectionMode == "single" ? (showTime ? "9999-99-99 99:99:99" : "9999-99-99") : undefined}
                    readOnlyInput={selectionMode != "single"}
                    touchUI={touchUI}
                />
                {errors && <LabelError id={forLabel} error={errors} />}
            </Col>
        </FormGroup>
    );
};

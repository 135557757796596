import React, { useEffect, useState, useMemo, useRef, SyntheticEvent } from "react";

import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";

import SuperscriptDisplay from "../../superscript/superscript-display";
import { IEditSelectInputProps } from "../interfaces/edit-interfaces";
import { sweetConfirm } from "../../sweet-alert/sweetConfirm";
import { getNestedObjValue } from "../helpers/primeHelpers";
import { ReqParamsHOC } from "../helpers/req-params-hoc";
// import { moveKeyPress } from "../helpers/moveKeyPress";
import { memoEditInput } from "./memoEditInput";

// table edit component, you send it in the column as editBody prop
// editBody: (inputData: IPrimeEditData<ProperInterface>) => (<EditSelectInput inputData={inputData} />),
const EditSelectInputBase = (props: IEditSelectInputProps) => {
    const {
        inputData: { row, fieldName, selectChoices },
        inputEnabled = true,
        CustomPlaceholder = undefined,
        superscript,
        value,
    } = props;

    if (inputEnabled) return <EnabledInput {...props} />;

    const content = () => {
        if (value) return value;
        if (fieldName.includes("label")) return getNestedObjValue(row, fieldName.split("."));
        else return selectChoices?.find((item) => item.value == getNestedObjValue(row, fieldName.split(".")))?.label;
    };

    return (
        <div className="input-placeholder" id="input-placeholder" key={`prime-input-select-${fieldName}-${row.id}`}>
            {CustomPlaceholder ? <CustomPlaceholder /> : superscript ? <SuperscriptDisplay value={content()} /> : content()}
        </div>
    );
};

export const EditSelectInput = memoEditInput(EditSelectInputBase);

const EnabledInput = (props: IEditSelectInputProps) => {
    const { t } = useTranslation();

    const {
        //base
        value = undefined,
        name = undefined,
        shouldDataSetRefresh: refresh = false,
        extraEditParams = {},
        editUrl = undefined,
        secondPartEditUrl = undefined,
        className = "",
        clientSideEdit = false,
        style = {},
        editParamsBeforeChange = undefined,
        extraParametersToSet = undefined,
        urlInstanceId,
        valueTemplate,
        itemTemplate,
        panelClassName,
        //common
        placeholder = "Choose value...",
        nextRecordOnEnter = false,
        //unique
        sweetConfirmMessage = undefined,
        customMoreModal = undefined,
        clearable = true,
        clearToEmptyString = false,
        optionFilter = false,
        superscript = false,
    } = props;

    const { row, fieldName, handleEditSubmit, selectChoices, isBusy, permCode = "", rowIndex, handleFocusSelectRecord } = props.inputData;

    const [error, setError] = useState<boolean>(false);
    const [selectValue, setSelectValue] = useState<number | string>(value || row[fieldName]);

    const formRef = useRef<HTMLButtonElement>(null);
    const valueRef = useRef<any>(null);

    const _selectChoices = superscript
        ? useMemo(() => {
              return selectChoices?.map((o, i) => ({
                  label: <SuperscriptDisplay key={`opt-${i}`} value={o.label} />,
                  stringValue: o.label.replace(/[↑↓→←]/gi, ""),
                  value: o.value,
              }));
          }, [selectChoices])
        : selectChoices;

    const _name = name || fieldName;

    useEffect(() => {
        setSelectValue(value || row[fieldName]);
    }, [row.id, row[fieldName], value]);

    useEffect(() => {
        error && setError(false);
    }, [row]);

    const _value = selectValue || "";

    const handleOnChange = async (e) => {
        if (sweetConfirmMessage && !(await sweetConfirm("Select", sweetConfirmMessage))) return;
        setSelectValue(e.target.value);
        // rowValue is needed for checking if value before change is different then value on blur'e

        valueRef.current = e.target.value;

        if (!permCode) handleSubmit(e, undefined);

        formRef.current && formRef.current.click();
    };

    const handleSubmit = async (e: SyntheticEvent, reqParams?: IReqParams) => {
        const _e = {
            target: {
                name: _name,
                value: !valueRef.current && clearToEmptyString ? "" : valueRef.current,
            },
        };
        const params = editParamsBeforeChange
            ? editParamsBeforeChange(extraEditParams, {
                  ...e,
                  ..._e,
              })
            : extraEditParams;

        const response = await handleEditSubmit({
            row: row,
            e: _e,
            secondPartEditUrl,
            extraColumnEditParams: params,
            refresh: refresh,
            clientSideEdit: clientSideEdit,
            extraParametersToSet: extraParametersToSet,
            editUrl,
            urlInstanceId,
            reqParams: reqParams,
        });

        if (typeof response == "number") setError(true);
    };

    //? For explanation go to weblab_frontend/src/components/prime-multi-select/prime-multi-select.tsx
    const preventScrolling = () => {
        window.scrollTo({ top: 0, left: 0 });
    };

    return (
        <ReqParamsHOC onSubmit={handleSubmit} permCode={permCode} formRef={formRef} onClose={undefined}>
            <Dropdown
                key={`prime-input-select-${fieldName}-${row.id}`}
                id={`prime-input-select-${fieldName}-${rowIndex}`}
                emptyMessage={t("No records found")}
                optionLabel="label"
                optionValue="value"
                className={`prime-table-cell-edit ${className} ${error ? "error" : ""} ${
                    (!clearable || !_value) && !customMoreModal ? "widerSpan" : ""
                } ${customMoreModal ? "no-dropdown-icon" : ""}`}
                panelClassName={panelClassName}
                style={{ width: "100%", ...style }}
                name={_name}
                options={_selectChoices}
                value={_value || value || row[fieldName]}
                placeholder={t(placeholder)}
                showClear={clearable && !!_value}
                filter={optionFilter}
                disabled={isBusy}
                onFocus={() => {
                    preventScrolling();
                    handleFocusSelectRecord(row);
                }}
                valueTemplate={valueTemplate}
                itemTemplate={itemTemplate}
                onChange={(e) => handleOnChange(e)}
                showOnFocus
            />
        </ReqParamsHOC>
    );
};

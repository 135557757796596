import React, { useState } from "react";

import { AddSamplingPointModal } from "../modals/add-edit-sampling-point-modal";
import { samplingSitesUrls } from "../../utils/sampling-sites-urls";
import { mapToIds } from "components/prime-data-table/helpers";
import { useFormData } from "hooks/useFormData";

import * as GT from "components";

import "../../modal-styles.scss";

interface ISamplingPointSelectProps {
    errors: string | string[];
    handleChange: (e: { target: { name: string; value: number[] } } | any) => void;
    value: number | undefined;
    labelSm?: number;
    colSm?: number;
    samplingSites: ILabelValue<number>[];
    disabled?: boolean;
}

interface ISamplingPointsFormDataBySiteResponse {
    SamplingPointSet: ILabelValue<number>[];
}

export const SamplingPointSelect = ({
    errors,
    handleChange,
    value,
    labelSm,
    colSm,
    samplingSites,
    disabled = false,
}: ISamplingPointSelectProps) => {
    const [openModal, setOpenModal] = useState<boolean>(false);

    const { formData: samplingPointOptions, refreshFormData: refreshSamplingPointOptions } = useFormData<
        ILabelValue<number>[],
        ISamplingPointsFormDataBySiteResponse
    >({
        url: samplingSitesUrls.samplingPointsFormDataBySiteIds,
        params: { params: samplingSites ? { sampling_sites: mapToIds(samplingSites, "value") } : {} },
        mapFormData: (formData) => formData.SamplingPointSet,
    });

    const handleSelectedSamplingPoint = (samplingPointId: number) => {
        refreshSamplingPointOptions();
        handleChange({ target: { name: "samplingPoint", value: samplingPointId } });
    };

    const handleCloseModal = () => setOpenModal(false);

    const modalDisabled = !samplingSites?.length;

    return (
        <>
            <GT.PrimeSelect
                labelSm={labelSm}
                colSm={colSm}
                rowClassName={`sampling-location-select-row ${!!value ? "filled" : ""}`}
                options={samplingPointOptions}
                label="Sampling point"
                name="samplingPoint"
                errors={errors}
                onChange={handleChange}
                disabled={disabled}
                value={value}
            >
                {!modalDisabled ? (
                    <GT.Icon className="sampling-location-modal-button" name={["fas", "plus"]} onClick={() => setOpenModal(true)} />
                ) : (
                    <></>
                )}
            </GT.PrimeSelect>
            {openModal && (
                <AddSamplingPointModal
                    handleCloseModal={handleCloseModal}
                    handleSelected={handleSelectedSamplingPoint}
                    samplingSites={samplingSites}
                    disabled={modalDisabled}
                />
            )}
        </>
    );
};

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import classnames from "classnames/dedupe";

import { OverflowMenu } from "./overflow-menu";
import Tabs from "../../tabs";

interface IVisibilityMap {
    [key: string]: { isVisible: boolean; order: number };
}

export default function ResponsiveTabsWrapper({ children, selectedTab, validTabs }) {
    const navRef = useRef<HTMLDivElement>(null);

    const [visibilityMap, setVisibilityMap] = useState<IVisibilityMap>(
        validTabs.reduce((acc, tab, index) => {
            acc[tab.name] = { order: index + 1, isVisible: true };
            return acc;
        }, {})
    );

    const handleIntersection = (entries) => {
        let updatedEntries = { ...visibilityMap };
        let lastVisibleElementKey: string = "";
        let activeElement: string = "";

        entries.forEach((entry, index) => {
            const targetid = entry.target.dataset.targetid;
            if (entry.target.dataset.isactive === "true") activeElement = targetid;
            let currentElement = updatedEntries[targetid];

            // Check if element is visible within container
            updatedEntries[targetid] = { ...currentElement, isVisible: entry.isIntersecting };
        });

        if (activeElement && !updatedEntries[activeElement].isVisible) {
            Object.entries(updatedEntries).forEach(([key, value]) => {
                if (value.isVisible && (!lastVisibleElementKey || updatedEntries[lastVisibleElementKey].order < value.order)) {
                    lastVisibleElementKey = key;
                }
            });

            if (lastVisibleElementKey) {
                updatedEntries[lastVisibleElementKey].isVisible = false;
                updatedEntries[activeElement].isVisible = true;
            }
        }

        // Overwrite previous state values with current state
        setVisibilityMap(updatedEntries);
    };

    useLayoutEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: navRef.current,
            rootMargin: "0px -40px 0px 0px",
            threshold: 1,
        });

        navRef.current &&
            Array.from((navRef.current?.firstChild as HTMLDivElement)?.children).forEach((item) => {
                if ((item as HTMLDivElement)?.dataset.targetid) {
                    observer.observe(item);
                }
            });

        return () => observer.disconnect();
    }, [validTabs, JSON.stringify(visibilityMap)]);

    useEffect(() => {
        if (visibilityMap[selectedTab] && !visibilityMap[selectedTab]?.isVisible)
            setVisibilityMap((prev) => {
                const _prev = { ...prev };
                let lastVisibleItem: { key?: string; order: number } = { order: 0 };

                Object.entries(_prev).forEach(([key, value]) => {
                    if (value.isVisible && lastVisibleItem.order < value.order) lastVisibleItem["key"] = key;
                });

                lastVisibleItem.key && (_prev[lastVisibleItem.key]["isVisible"] = false);
                selectedTab && (_prev[selectedTab]["isVisible"] = true);

                return _prev;
            });
    }, [selectedTab]);

    const isOverflowMenu = !!Object.values(visibilityMap).filter((tab) => tab.isVisible == false).length;

    return (
        <div ref={navRef} className="intersection-observer">
            <Tabs gtPills>
                {React.Children.map(children, (child, index) => {
                    const childData = visibilityMap[child.props["data-targetid"]];

                    return React.cloneElement(child, {
                        className: classnames(child.props.className, {
                            invisible: childData?.isVisible === false,
                        }),
                    });
                })}
                {isOverflowMenu && (
                    <OverflowMenu visibilityMap={visibilityMap}>
                        {React.Children.map(children, (child, index) => {
                            return visibilityMap[child.props["data-targetid"]]?.isVisible === false ? (
                                React.cloneElement(child, {
                                    className: "context-menu-option",
                                })
                            ) : (
                                <></>
                            );
                        })}
                    </OverflowMenu>
                )}
            </Tabs>
        </div>
    );
}

import React, { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { ITableDelivery, ITableDemandOrder, ITableDemandOrderPosition } from "../interfaces/interface";

import { IDemandOrderFormData } from "../../DemandsList/interfaces/interface";
import { useFormData } from "../../../../hooks/useFormData";

const urgent: ILabelValue[] = [
    { label: "Urgent", value: "True" },
    { label: "Not urgent", value: "False" },
];

export const DemandOrderContext = createContext<any>(null);

export const DemandOrderProvider = ({ children }) => {
    const { t } = useTranslation();
    const [selectedDemandOrders, setSelectedDemandOrders] = useState<ITableDemandOrder[]>([]);
    const [selectedDemandOrderPositions, setSelectedDemandOrderPositions] = useState<ITableDemandOrder[]>([]);
    const [selectedDeliveries, setSelectedDeliveries] = useState<ITableDelivery[]>([]);

    const { formData } = useFormData<IDemandOrderFormData>({
        url: `${window.location.pathname}/app-form-data`,
        mapFormData: (data): IDemandOrderFormData => ({
            ...data,
            urgent: urgent.map((o) => ({ ...o, label: t(o.label) })),
            months: data.months.map((o) => ({ ...o, label: t(o.label) })),
        }),
    });

    return (
        <DemandOrderContext.Provider
            value={{
                selectedDemandOrders,
                setSelectedDemandOrders,
                selectedDemandOrderPositions,
                setSelectedDemandOrderPositions,
                selectedDeliveries,
                setSelectedDeliveries,
                editable: selectedDemandOrders[0]?.workflowStep.editableData,
                formData: formData,
            }}
        >
            {children}
        </DemandOrderContext.Provider>
    );
};

export const useDemandOrder = (): IUseDemandOrder => {
    const {
        selectedDemandOrders,
        setSelectedDemandOrders,
        selectedDemandOrderPositions,
        setSelectedDemandOrderPositions,
        selectedDeliveries,
        setSelectedDeliveries,
        editable,
        formData,
    } = useContext(DemandOrderContext);

    return {
        selectedDemandOrders,
        setSelectedDemandOrders,
        selectedDemandOrderPositions,
        setSelectedDemandOrderPositions,
        selectedDeliveries,
        setSelectedDeliveries,
        editable,
        formData,
    };
};

interface IUseDemandOrder {
    selectedDemandOrders: ITableDemandOrder[];
    setSelectedDemandOrders: (arg: ITableDemandOrder[]) => void;
    selectedDemandOrderPositions: ITableDemandOrderPosition[];
    setSelectedDemandOrderPositions: (arg: ITableDemandOrderPosition[]) => void;
    selectedDeliveries: ITableDelivery[];
    setSelectedDeliveries: (arg: ITableDelivery[]) => void;
    editable: boolean;
    formData: IDemandOrderFormData;
}

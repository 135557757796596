import React, { CSSProperties } from "react";

import DurationFieldDisplay from "components/duration-field-display/duration-field-display";
import { DropdownPlaceholder, IDropdownPlaceholderProps } from "./dropdown-placeholder";
import SuperscriptDisplay from "components/superscript/superscript-display";
import PrettyCell, { IPrettyCellProps } from "./pretty-cell/pretty-cell";
import { TooltipContentType } from "components/gt-tooltip/gt-tooltip";
import { CheckboxInputPlaceholder } from "./checkbox-placeholder";
import { TextareaInputPlaceholder } from "./textarea-placeholder";
import { MultiItemPlaceholder } from "./multi-item-placeholder";
import { getNestedObjValue } from "../helpers/primeHelpers";
import { DefaultCell } from "../components/default-cell";

import * as I from "components/prime-data-table/interfaces";

interface IIDropdownPlaceholderConfig extends Omit<IDropdownPlaceholderProps, "rowData"> {}
interface ICheckboxPlaceholderConfig extends Omit<I.ICheckboxInputPlaceholderProps, "rowData"> {}
interface IMultiItemPlaceholderConfig extends Omit<I.IMultiSelectPlaceholder, "rowData"> {}
interface ITextareaPlaceholderConfig extends Omit<I.ITextareaInputPlaceholderProps, "rowData"> {}
interface IStatusConfig extends Omit<IPrettyCellProps, "text"> {
    value?: string;
}

interface ISuperscriptConfig extends Omit<IPrettyCellProps, "text"> {
    value?: string;
    style?: CSSProperties;
    className?: string;
}

export const dropdown = (rowData: I.IPrimeRowData<any>, placeholderConfig?: IIDropdownPlaceholderConfig) => (
    <DropdownPlaceholder rowData={rowData} {...placeholderConfig} />
);

export const checkbox = (rowData: I.IPrimeRowData<any>, placeholderConfig?: ICheckboxPlaceholderConfig) => (
    <CheckboxInputPlaceholder rowData={rowData} {...placeholderConfig} />
);

export const multiItem = (rowData: I.IPrimeRowData<any>, placeholderConfig?: IMultiItemPlaceholderConfig) => (
    <MultiItemPlaceholder rowData={rowData} {...placeholderConfig} />
);

export const textarea = (rowData: I.IPrimeRowData<any>, placeholderConfig?: ITextareaPlaceholderConfig) => (
    <TextareaInputPlaceholder rowData={rowData} {...placeholderConfig} />
);

export const status = (rowData: I.IPrimeRowData<any>, config?: IStatusConfig) => (
    <PrettyCell text={config?.value || getNestedObjValue(rowData.row, rowData.fieldName.split("."))} {...config} />
);

export const superscript = (rowData: I.IPrimeRowData<any>, config?: ISuperscriptConfig) => {
    const value = config?.value || getNestedObjValue(rowData.row, rowData.fieldName.split("."));
    return (
        <>
            {value && (
                <DefaultCell value={value}>
                    <SuperscriptDisplay value={value} {...config} />
                </DefaultCell>
            )}
        </>
    );
};

export const duration = <T,>(rowData: I.IPrimeRowData<T>, isWorkingDays: boolean = false) => {
    const value = getNestedObjValue(rowData.row, rowData.fieldName.split("."));
    return (
        <>
            {value && (
                <DefaultCell value={value}>
                    <DurationFieldDisplay text={value} isWorkingDays={isWorkingDays} />
                </DefaultCell>
            )}
        </>
    );
};

export const customCell = <T,>(rowData: I.IPrimeRowData<T>, content?: TooltipContentType, tooltipContent?: TooltipContentType) => {
    const defaultContent: string = getNestedObjValue(rowData.row, rowData.fieldName.split("."));

    return (
        <DefaultCell value={(content as string) || defaultContent} tooltipContent={tooltipContent || defaultContent}>
            {content || defaultContent}
        </DefaultCell>
    );
};

export const translate = (rowData: I.IPrimeRowData<any>) => {
    const value = getNestedObjValue(rowData.row, rowData.fieldName.split("."));

    return <DefaultCell value={value || ""}>{rowData.translate(value || "")}</DefaultCell>;
};

export const translateLabelValue = (rowData: I.IPrimeRowData<any>) => {
    const value = rowData.row?.[rowData.fieldName]?.label || "";

    return <DefaultCell value={value}>{rowData.translate(value)}</DefaultCell>;
};

export const translateNameId = (rowData: I.IPrimeRowData<any>) => {
    const value = rowData.row?.[rowData.fieldName]?.name || "";

    return <DefaultCell value={value}>{rowData.translate(value)}</DefaultCell>;
};

export const multipleValues = (rowData: I.IPrimeRowData<any>, instanceName = "label") => {
    const value = rowData.row?.[rowData.fieldName]?.map((item) => item[instanceName])?.join(" • ");

    return <DefaultCell value={value}>{value}</DefaultCell>;
};

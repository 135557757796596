/**
 * External Dependencies
 */
import { combineReducers } from "redux";
import Cookies from "js-cookie";
import Omit from "object.omit";
import axios from "axios";

/**
 * Internal Dependencies
 */
import { PRETTY_CELL_CONFIG } from "components/prime-data-table/body-components/pretty-cell/pretty-cell-helpers";
import defaultSettings from "../settings";
import { getUID } from "../utils";
import {
    UPDATE_AUTH,
    UPDATE_SETTINGS,
    ADD_TOAST,
    REMOVE_TOAST,
    UPDATE_PERMISSIONS,
    UPDATE_LANGUAGE,
    UPDATE_OPTIONS,
    UPDATE_REQUIREMENTS,
    UPDATE_STYLES,
    UPDATE_STATUS,
    UPDATE_META_APP_TYPES,
    UPDATE_SYSTEM_SETTINGS,
    UPDATE_SERVER_CONNECTION,
    LOG_OUT,
} from "../actions";
import { IMetaAppTypeTable } from "pages/BasicSettings/MetaAppTypes/interfaces/interfaces";

// initial state.
const INITIAL_SETTINGS_STATE = {
    ...defaultSettings,
};

const INITIAL_AUTH_STATE = {
    token: Cookies.get("jwtWb"),
    permissions: {},
    language: process.env.REACT_APP_LANGUAGE_CODE || "pl",
    name: "",
    laboratory: "",
    requirements: {},
    isLoggedIn: false,
};

const INITIAL_TOASTS_STATE = [];

const INITIAL_OPTIONS_STATE = {
    yaybarExpand: true,
    screenSize: { height: undefined, width: undefined },
};

const INITIAL_STYLES_STATE = {
    statuses: PRETTY_CELL_CONFIG,
};

const INITIAL_SYSTEM_SETTINGS = {};

const INITIAL_META_APP_TYPES_STATE = [];

const INITIAL_SERVER_CONNECTION_STATE = {
    isConnected: true,
    isRefreshing: false,
};

/**
 * Reducer
 */
const rootReducer = combineReducers({
    auth: (state: any = INITIAL_AUTH_STATE, action) => {
        switch (action.type) {
            case UPDATE_AUTH:
                //save token to cookies for 2 days.
                if (typeof action.payload.token !== "undefined") {
                    Cookies.set("jwtWb", action.payload.token, { expires: 2 });
                }

                // const keys = Object.keys(action.payload)
                // keys.forEach( k => state[k] = action.payload[k] )
                // return { ...state }
                return {
                    ...(state as object),
                    ...action.payload,
                };
            case UPDATE_PERMISSIONS:
                return {
                    ...(state as {}),
                    permissions: action.payload,
                };
            case UPDATE_LANGUAGE:
                axios.defaults.headers.common["lng"] = action.payload;
                return {
                    ...(state as {}),
                    language: action.payload,
                };
            case UPDATE_REQUIREMENTS:
                return {
                    ...(state as {}),
                    requirements: action.payload,
                };
            case LOG_OUT:
                return {
                    ...state,
                    requirements: {},
                    permissions: {},
                    token: "",
                    name: "",
                    laboratory: "",
                    isLoggedIn: false,
                };
            default:
                return state;
        }
    },
    options: (state = INITIAL_OPTIONS_STATE, action) => {
        switch (action.type) {
            case UPDATE_OPTIONS:
                return {
                    ...(state as {}),
                    ...action.payload,
                };
            default:
                return state;
        }
    },
    settings: (state = INITIAL_SETTINGS_STATE, action) => {
        switch (action.type) {
            case UPDATE_SETTINGS:
                return Object.assign({}, state, action.settings);
            default:
                return state;
        }
    },
    systemSettings: (state = INITIAL_SYSTEM_SETTINGS, action) => {
        switch (action.type) {
            case UPDATE_SYSTEM_SETTINGS:
                return Object.assign({}, state, action.systemSettings);
            default:
                return state;
        }
    },
    styles: (state: any = INITIAL_STYLES_STATE, action) => {
        switch (action.type) {
            case UPDATE_STYLES:
                return Object.assign({}, state, action.styles);
            case UPDATE_STATUS:
                return { ...state, statuses: { ...state.statuses, ...action.status } };
            default:
                return state;
        }
    },
    toasts: (state = INITIAL_TOASTS_STATE, action) => {
        switch (action.type) {
            case ADD_TOAST:
                const newData = {
                    ...{
                        title: "",
                        content: "",
                        color: "brand",
                        time: false,
                        duration: 0,
                        closeButton: true,
                    },
                    ...action.data,
                };

                if (newData.time === true) {
                    newData.time = new Date();
                }

                return {
                    ...(state as object),
                    [getUID()]: newData,
                };
            case REMOVE_TOAST:
                if (!action.id || !(state as object)[action.id]) {
                    return state;
                }
                return Omit(state, action.id);
            default:
                return state;
        }
    },
    metaAppTypes: (state = INITIAL_META_APP_TYPES_STATE, action) => {
        switch (action.type) {
            case UPDATE_META_APP_TYPES:
                const metaAppTypes: IMetaAppTypeTable[] = action.payload.metaAppTypes;

                return metaAppTypes
                    .filter((metaAppType) => metaAppType.status != "retired")
                    .map((metaAppType) => ({
                        name: metaAppType.name,
                        icon: metaAppType.icon,
                        status: metaAppType.status,
                        model: metaAppType.modelName,
                    }));
            default:
                return state;
        }
    },
    serverConnection: (state: any = INITIAL_SERVER_CONNECTION_STATE, action) => {
        switch (action.type) {
            case UPDATE_SERVER_CONNECTION:
                const { isConnected, isRefreshing } = action.payload;

                return { isRefreshing, isConnected };
            default:
                return state;
        }
    },
});

export default rootReducer;

import React, { useState } from "react";

import { Modal, ModalBody } from "reactstrap";
import Axios from "axios";

import { InputSwitch } from "components/input-switch/input-switch";
import { auditColumns, historyColumns } from "./audit-columns";
import { useFetchTableData } from "hooks/useFetchTableData";
import { downloadFile } from "utils/file/download-file";
import { handleRequest } from "utils/handleRequest";
import { GT } from "components/index";
import addToast from "utils/addToast";

export const AuditTable = (props) => {
    const { closeModal, auditData, instance, tableColumns } = props;
    const { model, appLabel } = auditData;

    const [historyToggle, setHistory] = useState<boolean>(false);

    const toggleHistory = (e) => {
        setHistory((prev) => !prev);
    };

    const { data, handleReload } = useFetchTableData({
        url: "audit",
        configParams: { params: { hideToast: true } },
        additionalParams: `&appLabel=${appLabel}&model=${model}&instanceId=${instance?.id}`,
        onSuccess: (dataSet) => {
            if (!dataSet[0]) {
                closeModal();
                addToast({ title: "No audit events found", content: "or audit for this table is disabled", color: "danger" });
                return;
            }
        },
        onError: (err: any) => {
            console.log(err);
            addToast({ title: "No audit events found", content: "or audit for this table is disabled", color: "danger" });
        },
    });

    const handleExport = async () => {
        await handleRequest({
            request: () =>
                Axios.post("export-audit-to-xlsx", {
                    modelName: model,
                    appLabel,
                    instanceId: instance?.id,
                }),
            onSuccess: (response) => {
                downloadFile({ data: { file: response.data?.file.replace("media/", ""), name: `${appLabel}-${model}-audit` } });
            },
        });
    };

    return (
        <Modal className="audit-window" isOpen={true} fade size="xxl">
            <GT.ModalHeader closeModal={closeModal} name="Audit" />
            <ModalBody style={{ overflowY: "auto" }}>
                <InputSwitch onChange={toggleHistory} checked={historyToggle} label="Record preview" labelSm={2} />
                <GT.PrimeDataTable
                    tableName={!historyToggle ? `audit-table-${appLabel}-${model}` : `audit-record-preview`}
                    notSelectFirst
                    onReload={handleReload}
                    dataSet={data?.data}
                    meta={data?.meta}
                    columns={!historyToggle ? auditColumns : [...historyColumns, ...tableColumns.map((col) => ({ ...col, width: 150 }))]}
                    idSelector="historyId"
                    defaultTableHeight={700}
                >
                    <GT.Button text="Export XLSX" onClick={handleExport} withoutPerm />
                </GT.PrimeDataTable>
            </ModalBody>
            <GT.ModalFooter closeModal={closeModal} cancelName="Close" />
        </Modal>
    );
};

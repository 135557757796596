import { useCallback, useEffect, useState } from "react";

import Axios, { CancelToken, Canceler } from "axios";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { addToTableRequest, patchTableRequest } from "utils/tableOperations";
import { updateServerConnection } from "actions/index";
import { useDebounce } from "./useDebounce";

interface IUseFetchTableData<T> {
    url: string | undefined;
    triggerValues?: any[];
    initParams?: string;
    shouldFetch?: any;
    additionalParams?: string;
    configParams?: any;
    getLocation?: boolean;
    groupedOnInit?: boolean;
    mapData?: (data: T[]) => any;
    onUnmount?: () => void;
    onSuccess?: (data: T[]) => any;
    onError?: (err: any) => void;
}

const REFRESH_REQUEST_TIME = 3000;

let refreshCounter: number = 0;

//! TODO:
//! There is no pending state, that may cause to unexpected situations
//! when the client or the server connection is slow
export function useFetchTableData<T = any, K = T>({
    url,
    triggerValues = [],
    initParams = "?pageSize=10&page=1",
    shouldFetch = undefined,
    additionalParams = "",
    configParams = undefined,
    getLocation = false,
    groupedOnInit = false,
    mapData,
    onSuccess,
    onUnmount = undefined,
    onError = undefined,
}: IUseFetchTableData<T>) {
    const [data, setData] = useState<IData<K>>(undefined);
    const [urlParams, setParams] = useState<string>(initParams);

    const { state } = useLocation<{ instanceId: number }>();

    const dispatch = useDispatch();

    const { isRefreshing } = useSelector((state) => ({ isRefreshing: state.serverConnection.isRefreshing }));

    // MAIN FETCHING FUNCTION
    const getData = async (token?: CancelToken, cancel?: Canceler) => {
        let request = { ...configParams };

        if (!url) {
            cancel && cancel();
            setData({ data: [], meta: undefined });
            onSuccess && onSuccess([]);
            return;
        }

        const locationParams = getLocation && state?.instanceId ? `&search=${state?.instanceId}&search_field=id` : "";

        try {
            const response = await Axios.get(url + urlParams + additionalParams + locationParams, {
                ...request,
                cancelToken: token,
            });

            const data = response.data.data ? response.data.data : response.data;
            const dataToSet = mapData ? mapData(data) : data;

            setData({ ...response.data, data: dataToSet, meta: response.data.meta });
            onSuccess && onSuccess(dataToSet);

            if (isRefreshing) {
                dispatch(updateServerConnection({ isRefreshing: false, isConnected: true }));
                refreshCounter = 0;
            }
        } catch (err: any) {
            console.error(err);
            onError && onError(err);
            if (err.message == "Network Error" && navigator.onLine) {
                if (refreshCounter == 10) dispatch(updateServerConnection({ isConnected: false }));
                else {
                    if (!isRefreshing) dispatch(updateServerConnection({ isRefreshing: true, isConnected: true }));
                    setTimeout(() => {
                        refreshCounter += 1;
                        return refreshData();
                    }, REFRESH_REQUEST_TIME);
                }
            }
        }
    };

    // As if the hook is used mostly to maintains tables and it's refreshed on changing records
    // we need to add debounce on changing records
    triggerValues = triggerValues.map((value) => useDebounce(value, 250, 0));

    useEffect(() => {
        if (!(shouldFetch != false)) return;
        const source = Axios.CancelToken.source();

        if (groupedOnInit && !urlParams.includes("grouping=")) return;

        getData(source.token, source.cancel);

        return () => source.cancel();
    }, [urlParams, ...triggerValues, shouldFetch]);

    // Function refresh makes it a little bit harder to maintain but it is far more optimal
    // and that way we can await for the refresh before closing modals
    //! When passing refresh data with a useRef hook do not pass it directly to props like
    //! refresh={refreshRef?.current?.refresh} but do refreshRef={refreshRef} to keep the reference
    //! otherwise it might cause bugs
    const refreshData: () => void = async () => {
        await getData();
    };

    // Helper function that it is used to add record to table from the post response
    const addRecord = useCallback(
        ({ newRecord, customUrl, parseResponse, onResponse }: IAddRecord) =>
            addToTableRequest(customUrl ?? url, newRecord, setData, parseResponse, onResponse),
        [url]
    );

    // Helper function that it is used to modify record to from the patch response
    const patchRecord = useCallback(
        ({ newRecord, customUrl, recordId, onResponse }: IPatchRecord) =>
            patchTableRequest(customUrl ?? url + `/${recordId}`, newRecord, setData, onResponse),
        [url]
    );

    // Helper function that it is used to refresh data with specified params
    const handleReload = (params?: string, refresh?: boolean) => {
        if (refresh) refreshData();
        else if (params) {
            setParams(params);
        }
    };

    useEffect(() => {
        return () => {
            onUnmount && onUnmount();
        };
    }, []);

    return { data, setData, refreshData, handleReload, addRecord, patchRecord, urlParams };
}

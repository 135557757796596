import React, { createContext, useContext, useState } from "react";
import { useParams } from "react-router-dom";

export const QueryTemplateContext = createContext<any>(null);

interface ISectionVisible {
    crossTable: boolean;
    treeTable: boolean;
}

export const QueryTemplateContextProvider = ({ children }) => {
    const params: { id: string } = useParams();

    const [qbe, setQbe] = useState<number | undefined>(isNaN(Number(params?.id)) ? undefined : Number(params?.id));
    const [qbeOptions, setQbeOptions] = useState<ILabelValue<number>[]>([]);

    const [sectionVisible, setSectionVisible] = useState<ISectionVisible>({ crossTable: false, treeTable: false });
    const [pivotTableTemplate, setPivotTableTemplate] = useState<any | undefined>(undefined);
    const [selectedOpts, setSelectedOpts] = useState<any>([]);

    const [refreshCrossTableData, setRefreshCrossTableData] = useState<Function | undefined>(undefined);
    const [refreshTreeTableData, setRefreshTreeTableData] = useState<Function | undefined>(undefined);

    const toggleVisibleCrossTable = () => setSectionVisible({ treeTable: false, crossTable: !sectionVisible.crossTable });
    const toggleVisibleTreeTable = () => setSectionVisible({ crossTable: false, treeTable: !sectionVisible.treeTable });
    const closeAdditionalTables = () => setSectionVisible({ crossTable: false, treeTable: false });

    return (
        <QueryTemplateContext.Provider
            value={{
                qbe,
                setQbe,
                qbeOptions,
                setQbeOptions,
                sectionVisible,
                setSectionVisible,
                toggleVisibleCrossTable,
                toggleVisibleTreeTable,
                closeAdditionalTables,
                pivotTableTemplate,
                setPivotTableTemplate,
                selectedOpts,
                setSelectedOpts,
                refreshCrossTableData,
                setRefreshCrossTableData,
                refreshTreeTableData,
                setRefreshTreeTableData,
            }}
        >
            {children}
        </QueryTemplateContext.Provider>
    );
};

export const useQueryTemplate = (): IUseQueryTemplate => {
    const {
        qbe,
        setQbe,
        qbeOptions,
        setQbeOptions,
        sectionVisible,
        setSectionVisible,
        toggleVisibleCrossTable,
        toggleVisibleTreeTable,
        closeAdditionalTables,
        pivotTableTemplate,
        setPivotTableTemplate,
        selectedOpts,
        setSelectedOpts,
        refreshCrossTableData,
        setRefreshCrossTableData,
        refreshTreeTableData,
        setRefreshTreeTableData,
    } = useContext(QueryTemplateContext);

    return {
        qbe,
        setQbe,
        qbeOptions,
        setQbeOptions,
        sectionVisible,
        setSectionVisible,
        toggleVisibleCrossTable,
        toggleVisibleTreeTable,
        closeAdditionalTables,
        pivotTableTemplate,
        setPivotTableTemplate,
        selectedOpts,
        setSelectedOpts,
        refreshCrossTableData,
        setRefreshCrossTableData,
        refreshTreeTableData,
        setRefreshTreeTableData,
    };
};

interface IUseQueryTemplate {
    qbe: any;
    setQbe: Function;
    qbeOptions: ILabelValue<number>[];
    setQbeOptions: Function;
    sectionVisible: ISectionVisible;
    setSectionVisible: Function;
    toggleVisibleCrossTable: Function;
    toggleVisibleTreeTable: Function;
    closeAdditionalTables: Function;
    pivotTableTemplate: any | undefined;
    setPivotTableTemplate: Function;
    selectedOpts: any;
    setSelectedOpts: Function;
    refreshCrossTableData: Function;
    setRefreshCrossTableData: Function;
    refreshTreeTableData: Function;
    setRefreshTreeTableData: Function;
}

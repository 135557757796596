// external import
import { DataTable, DataTableColReorderParams, DataTableColumnResizeEndParams, DataTablePageParams } from "primereact/datatable";
import React, { useState, useEffect, useRef, useCallback, useMemo, useLayoutEffect, memo } from "react";
import { ContextMenu } from "primereact/contextmenu";
import { useTranslation } from "react-i18next";
import classnames from "classnames/dedupe";
import { useSelector } from "react-redux";

// internal import
import { ResponsiveButtonsContainer } from "../../responsive-buttons-wrapper/intersectionButtons";
import { AdvanceFiltersWrapper } from "../components/pop-ups/advanceFilters/advance-filters";
import { AdvancedRemarksPopup } from "../components/pop-ups/advanced-remarks-popup";
import { AdvancedRemarksContextProvider } from "../advancedRemarksContext/context";
import { LoadingMessage } from "../components/messages/loading-message";
import { EmptyMessage } from "../components/messages/empty-message";
import { MemoRowGroupHeader } from "../components/row-group-header";
import { sweetConfirm } from "../../sweet-alert/sweetConfirm";
import { applySubmit } from "../helpers/applySubmit";
import { deepCopy } from "utils/memo/deepCopy";

// table components
import { PrimeContextMenu } from "../components/contextMenu/context-menu";

import { useDebouncedCallback } from "hooks"; // hooks

// interfaces
import * as FI from "../interfaces/filter-interfaces"; // internal filters interfaces import
import * as I from "../interfaces"; // internal table interfaces import
import * as H from "../helpers"; // helpers import

// internal style import
import "./../style.scss";

// TODO! wyświetlanie odpowiednich filtrów i input'ów w additionalColumns (flaga że kolumna jest additional?????)
// one record in tbody should have minimum 31px, if this changes you have to update this variable.
const RECORDS_HEIGHT: number = 31;
export const PrimeDataTable = <T extends I.IBaseRecord = I.IBaseRecord>(props: I.IPrimeDataTable<T>) => {
    const {
        dataSet = [],
        setData,
        columns,
        tableName,
        dynamicColumns = false,
        // start visual settings section
        className = "",
        children,
        btnContainerClassName,
        showGridlines = true,
        reorderColumnsEnabled = true,
        resizableColumnsEnabled = true,
        minimumColWidth = 123, // 123 is a perfect number for table to scalable in normal and scroll mode
        defaultTableHeight = 155, // to get a table height as a whole you need to subtract 85px and height of buttons
        disableHeadersTranslation = false,
        // end visual settings section

        // start pagination section
        meta,
        onReload = undefined,
        rowsPerPage = [5, 10, 15, 25, 50],
        // end pagination section
        // start select record section
        selectedRecords = [],
        onSelect = undefined,
        notSelectFirst = false,
        selectionMode = "multiple",
        idSelector = "id",
        // end select record section

        // start sorting and grouping section
        sortable = true,
        higherDependencyId = [],
        onParamsChange,
        groupRowsEnabled = true,
        customRowGroupHeader = undefined,
        customRowGroupFooter = undefined,
        useFetchTableData = false,
        // end sorting and grouping section

        // start advanced filter section
        advancedFiltersConfig = undefined,
        // end advanced filter section

        // start context menu section, if any prop below is enabled or has correct data, then context menu will be enabled and context menu item will show when right clicked on table
        auditData = undefined,
        exportCSVEnabled = false,
        hidingColumnEnabled = true,
        filterEnabled = true,
        frozenColumnEnabled = true,
        translateDisabled = false,
        additionalColumnsEnabled = false,
        contextMenuItems = [],
        onContextColumnFieldChange = undefined,
        // end context menu section

        handleDoubleClick = undefined,

        // start edit section
        onEdit = undefined,
        handleCustomResponse = undefined,
        extraEditParams = {},
        firstPartEditUrl = "",
        refreshCallBackFunctions: refreshCallBack = [],
        customHandleEditSubmit = undefined,
        hideSuccessToast = false,
        // start edit section
    } = props;
    const { t } = useTranslation();

    const tableContainerRef = useRef<HTMLDivElement>(null);
    const contextMenuRef = useRef<ContextMenu>(null);

    const { isRefreshing } = useSelector((state) => ({ isRefreshing: state.serverConnection.isRefreshing }));

    const [selection, onSelection] = useState<number[]>([]);

    const [filterFocusedFieldName, setFilterFocusedFieldName] = useState<{ fieldName: string; tableName: string }>({
        fieldName: "",
        tableName: "",
    }); // handle input field name for solving focus loss problem

    // Meta data
    const [paginationMeta, setPaginationMeta] = useState<I.IPrimeMeta>({
        first: 0,
        rows: meta?.pageSize,
        page: 1,
        totalRecords: meta?.count,
    });

    // Param data, this variable stores data about filters, sort and grouping
    const [params, setParams] = useState<I.IPrimeParams>({ filters: undefined, multiSortMeta: undefined });

    // Column Data, this variable stores data about currently visible columns and default(but translated) columns
    const [generatedColumns, setGeneratedColumns] = useState<I.IGeneratedPrimeColumns>({
        visible: [],
        default: [],
        columnGenerateStatus: "NOT_GENERATED_COLUMNS",
    });

    const [tableConfig, setTableConfig] = useState<I.ITableConfig>({
        contextItemFilterEnable: false,
        filtersEnabled: true,
        frozenColumn: undefined,
        tableHeight: defaultTableHeight,
    });

    const [scrollConfig, setScrollConfig] = useState<I.IScrollConfig | undefined>(undefined);
    const [isBusy, setIsBusy] = useState<boolean>(false);

    //! USEMEMO SECTION START
    const tableEnabledFunctionality: I.ITableEnabledFunctionality = useMemo(() => {
        const _sortable: boolean = !!onReload && sortable;

        return {
            pagination: !!onReload && !!paginationMeta && !!paginationMeta.rows,
            groupingEnabled: _sortable && groupRowsEnabled,
            groupingSelected: _sortable && groupRowsEnabled && !!params.multiSortMeta?.[0]?.groupByThisField,
            sortable: _sortable,
        };
    }, [onReload, sortable, !!paginationMeta, paginationMeta.rows, params?.multiSortMeta, groupRowsEnabled]);

    const tableClassName: string = useMemo((): string => {
        const frozenColumnClass = tableConfig.frozenColumn ? "frozen-mode" : "";
        const verticalScrollClass = `vertical-scroll ${scrollConfig?.vertical ? "enabled" : "disabled"}`;
        const disableLastColResize = !scrollConfig?.horizontal ? "disable-last-col-resize" : "";
        const filtersEnabled = tableConfig.filtersEnabled ? "filters-enabled" : "";

        return `${frozenColumnClass} ${verticalScrollClass} ${disableLastColResize} ${filtersEnabled}`;
    }, [tableConfig.frozenColumn, tableConfig.filtersEnabled, scrollConfig]);
    //! USEMEMO SECTION STOP

    //scroll useEffect
    useEffect(() => {
        H.setCssVariable(tableContainerRef, "--table-min-height", `${defaultTableHeight}px`);
    }, []);

    useEffect(() => {
        if (
            JSON.stringify(
                H.mapToIds(
                    dataSet.filter((record) => H.mapToIds(selectedRecords).includes(record[idSelector])),
                    idSelector
                )
            ) != JSON.stringify(H.mapToIds(selectedRecords, idSelector)) ||
            !selectedRecords.length
        ) {
            Array.isArray(selectedRecords) && onSelect && H.setSelected(dataSet, notSelectFirst, idSelector, onSelection, selection);
        }
    }, [JSON.stringify(dataSet)]);

    useEffect(() => {
        Array.isArray(selectedRecords) && !params.filters && dataSet[0]?.[idSelector] && onSelect && onSelection([dataSet[0][idSelector]]);
    }, [dataSet[0]?.[idSelector]]);

    const _selection = dataSet.filter((record) => selection.includes(record[idSelector]));

    useEffect(() => {
        onSelect && onSelect(_selection);
    }, [selection, JSON.stringify(dataSet)]);

    useEffect(() => {
        scrollConfig && handleScrollConfig("vertical");
    }, [dataSet.length]);

    // useEffect(() => {
    //     scrollConfig && handleScrollConfig("vertical");
    // }, [selectedRecords]);

    useEffect(() => {
        if (generatedColumns.columnGenerateStatus != "GENERATED_COLUMNS") handleTableColumns();

        if (meta?.page) {
            setPaginationMeta({
                first: meta?.page < 2 ? 0 : (meta?.page - 1) * meta?.pageSize,
                rows: meta?.pageSize,
                page: meta?.page ? meta?.page : 0,
                totalRecords: meta?.count,
            });
        }
    }, [JSON.stringify(meta)]);

    useEffect(() => {
        if (generatedColumns.columnGenerateStatus != "NOT_GENERATED_COLUMNS") handleTableColumns();
    }, [JSON.stringify(columns)]);

    useEffect(() => {
        // checks which scrolls should be enabled, on init when visible columns are already generated, and when visible columns changes
        if (generatedColumns.visible && generatedColumns.visible.length > 0) handleScrollConfig("default");
    }, [JSON.stringify(generatedColumns.visible)]);

    useEffect(() => {
        // this event listener has to be added every time when visible columns changes
        if (generatedColumns.visible && generatedColumns.visible.length > 0)
            window.addEventListener("resize", () => handleScrollConfig("horizontal"));
        return () => window.removeEventListener("resize", () => handleScrollConfig("horizontal"));
    }, [JSON.stringify(scrollConfig)]);

    useEffect(() => {
        if (params.multiSortMeta && params.multiSortMeta[0].groupByThisField) {
            setParams({ filters: undefined, multiSortMeta: [params.multiSortMeta[0]] });
        } else if (!!params.filters || !!params.multiSortMeta) {
            setParams({ filters: undefined, multiSortMeta: undefined });
        }
    }, [JSON.stringify(higherDependencyId)]);

    useLayoutEffect(() => {
        //! do usunięcia jak frozen col będzie zrobiony i wiersze nie będą możliwe do rozwijania
        const dataSetLength = dataSet?.length;

        if (dataSetLength > 0 && dataSetLength <= H.getDataSetBreakpoint(tableConfig.tableHeight, RECORDS_HEIGHT)) {
            if (scrollConfig && !scrollConfig?.vertical) {
                const verticalScroll = H.isVerticalScrollEnabled(tableContainerRef, tableConfig.tableHeight, tableConfig.filtersEnabled);

                verticalScroll && setScrollConfig({ vertical: true, horizontal: scrollConfig?.horizontal });
            }
        }
    });

    // generates request for saveTableConfig function and saves new config
    const handleSaveTableConfig = async (action: I.ListActionType) => {
        let request: I.IUserTableConfig = {
            columns: generatedColumns.visible,
            filtersEnabled: tableConfig.filtersEnabled,
        };

        switch (action.type) {
            case "columns":
                request.columns = action.payload.columns;
                break;
            case "filterEnabled":
                request.filtersEnabled = action.payload.filterEnabled;
                break;
        }

        await H.saveTableConfig(tableName, request);
    };

    // Configuring table options based on columns and user saved table config
    const handleTableColumns = async () => {
        const additionalColumnsPromise: Promise<I.IPrimeTableColumn[]> = H.mapToAdditionalColumns(
            meta?.ExtraFields,
            additionalColumnsEnabled
        );

        let _columns: I.IGeneratedPrimeTableColumn[];
        // Generating default columns

        let defaultColumns: I.IGeneratedPrimeTableColumn[];
        let tableConfigResponse: I.IUserTableConfig | undefined = undefined;

        // Fetching columns, validating fetched columns with generated default columns, and if fetched columns exist, mapping default columns to user saved columns
        if (!dynamicColumns) {
            const tableConfigPromise = H.getTableConfig(tableName);

            const [tableConfigResponse, additionalColumns] = await Promise.all([tableConfigPromise, additionalColumnsPromise]);

            _columns = H.mapColumnsOnInit(
                tableContainerRef,
                [...columns, ...additionalColumns],
                !disableHeadersTranslation ? t : undefined,
                minimumColWidth
            );

            defaultColumns = _columns;

            if (tableConfigResponse && tableConfigResponse?.columns?.length > 0) {
                _columns = await H.compareColumns(
                    tableConfigResponse.columns,
                    defaultColumns,
                    meta === undefined ? undefined : () => handleSaveTableConfig({ type: "columns", payload: { columns: defaultColumns } })
                );
            } else if (_columns.length > 0) {
                // saving generated columns in DB if table config does not exist
                meta === undefined && handleSaveTableConfig({ type: "columns", payload: { columns: defaultColumns } });
            }
        } else {
            const additionalColumns: I.IPrimeTableColumn[] = await Promise.resolve(additionalColumnsPromise);

            _columns = H.mapColumnsOnInit(
                tableContainerRef,
                [...columns, ...additionalColumns],
                !disableHeadersTranslation ? t : undefined,
                minimumColWidth
            );
            defaultColumns = _columns;
        }

        // saving visible columns in state
        setGeneratedColumns({
            visible: _columns,
            default: defaultColumns,
            columnGenerateStatus: meta === undefined ? "PLACEHOLDER_COLUMNS" : "GENERATED_COLUMNS",
        });

        // configure rest of the table settings
        handleTableConfig(_columns, tableConfigResponse);
    };

    const handleTableConfig = (columns: I.IGeneratedPrimeTableColumn[], fetchedTableConfig?: I.IUserTableConfig) => {
        let filtersEnabled = false;
        let tableHeight = tableConfig.tableHeight;
        let groupedColumn: I.DataTableSortMeta[] | undefined;

        // checking if filter should be opened
        if (onReload) {
            filtersEnabled = fetchedTableConfig?.filtersEnabled ? fetchedTableConfig?.filtersEnabled : tableConfig.filtersEnabled;
        }

        // if filter is opened then we have to change tableHeight and scss variable --table-min-height
        if (filtersEnabled) {
            const filterHeight: number = parseInt(H.getCssVariable(tableContainerRef, "--header-height"));
            tableHeight = filterEnabled ? tableHeight - filterHeight : tableHeight + filterHeight;

            H.setCssVariable(tableContainerRef, "--table-min-height", `${defaultTableHeight || tableHeight}px`);
        }

        let config: I.ITableConfig = { frozenColumn: undefined, contextItemFilterEnable: false, tableHeight, filtersEnabled };

        columns.forEach((col, index) => {
            // if there is even one filterBody in columns then context menu show/hide menu item will be enabled
            if (!!onReload && !!col.filterBody && !config.contextItemFilterEnable) {
                config.contextItemFilterEnable = true;
            }

            // checks if there is column that should be frozen on init, it also sets frozen col width
            if (col.frozen && !config.frozenColumn) {
                config.frozenColumn = {
                    field: col.field,
                    width: H.getFrozenColWidth(col),
                    originalPosition: index,
                };
                delete col.frozen;
            }

            // checks if there is column that should be grouped on init
            if (col.grouped && !groupedColumn) {
                groupedColumn = [{ header: col.header, field: col.field, order: 1, groupByThisField: true }];
                delete col.grouped;
            }
        });

        //if there is column that should be grouped on init then onSort function is fired
        if (groupedColumn) {
            handleSort({ multiSortMeta: groupedColumn }, true, columns);
            !useFetchTableData && onReload && onReload(undefined, true);
        }

        setTableConfig(config);
    };

    // Resize event listener, it checks if table should be in horizontal scroll mode or not
    const handleScrollConfig = (
        type: I.HandleScrollConfigType,
        tableHeight: number = tableConfig.tableHeight,
        filtersEnabled: boolean = tableConfig.filtersEnabled
    ) => {
        let _scrollConfig: I.IScrollConfig = {
            vertical: scrollConfig?.vertical || false,
            horizontal: scrollConfig?.horizontal || false,
        };

        switch (type) {
            case "horizontal":
                _scrollConfig.horizontal = H.isHorizontalScrollEnabled(tableContainerRef, generatedColumns.visible);

                break;
            case "vertical":
                _scrollConfig.vertical =
                    dataSet?.length > H.getDataSetBreakpoint(tableHeight, RECORDS_HEIGHT) ||
                    H.isVerticalScrollEnabled(tableContainerRef, tableHeight, filtersEnabled);

                break;
            default:
                _scrollConfig = {
                    ..._scrollConfig,
                    vertical:
                        dataSet?.length > H.getDataSetBreakpoint(tableHeight, RECORDS_HEIGHT) ||
                        H.isVerticalScrollEnabled(tableContainerRef, tableHeight, filtersEnabled),
                    horizontal: H.isHorizontalScrollEnabled(tableContainerRef, generatedColumns.visible),
                };
                break;
        }

        if (JSON.stringify(scrollConfig) != JSON.stringify(_scrollConfig)) setScrollConfig(_scrollConfig);
    };

    const handlePage = (event: DataTablePageParams) => {
        // this if is necessary because onPage was called twice when changing pageSize,
        // once when pageSize was changed, and second time when meta with new pageSize came
        if (event.page + 1 == paginationMeta?.page && event.rows == paginationMeta.rows) return;
        const { filters, multiSortMeta } = params;

        const urlParams = H.generateUrlParams({
            page: event.page + 1,
            rows: event.rows,
            multiSortMeta: mapMultiSortMeta(multiSortMeta),
            filters,
        });

        onReload && onReload(urlParams); // triggers dataReload
    };

    const handleSort = async (event, group: boolean = false, columns: I.IGeneratedPrimeTableColumn[] | undefined = undefined) => {
        const paramsSort = params.multiSortMeta;
        let eventSort: any | undefined = event.multiSortMeta;

        if (eventSort?.length == 0) eventSort = undefined;
        else if (!group && paramsSort?.[0].groupByThisField && eventSort[0].order != paramsSort[0].order) {
            eventSort = eventSort.map((s, index) => (index == 0 ? { ...paramsSort[0], order: eventSort[0].order } : s));
        }

        const urlParams = H.generateUrlParams({
            rows: paginationMeta?.rows,
            multiSortMeta: mapMultiSortMeta(eventSort, columns),
            filters: params.filters,
        });

        setParams({ ...params, multiSortMeta: eventSort });

        onReload && onReload(urlParams, undefined); // triggers dataReload
        onParamsChange && onParamsChange({ ...params, multiSortMeta: eventSort });
    };

    const mapMultiSortMeta = (
        multiSortMeta?: I.DataTableMultiSortMetaType,
        columns: I.IGeneratedPrimeTableColumn[] = generatedColumns.visible
    ) => {
        if (!multiSortMeta) return multiSortMeta;
        const filteredVisibleColumns = columns.filter((col) => col.sortFieldName);

        let mappedMultiSortMeta: I.DataTableSortMeta[] = multiSortMeta;

        if (filteredVisibleColumns.length > 0) {
            mappedMultiSortMeta = multiSortMeta.map((sortCol) => {
                const visibleCol = filteredVisibleColumns.find((visibleCol) => visibleCol.field == sortCol.field);
                return { ...sortCol, field: visibleCol ? (visibleCol?.sortFieldName as string) : sortCol?.field };
            });
        }

        return mappedMultiSortMeta;
    };

    const handleFilterChange = (input: I.IPrimeFilterInput, filterType: FI.FilteringType) => {
        setParams((prev) => {
            let filtersCopy = deepCopy(prev.filters);
            const multiSortMetaCopy = deepCopy(prev.multiSortMeta);
            let { name, value } = input;

            if (name.includes(".")) name = H.convertDotsToUnderscores(name);

            if (!!value) {
                filtersCopy = { ...filtersCopy, [name]: [{ value, filteringType: filterType }] };
            } else if (filtersCopy?.[name]) {
                if (Object.keys(filtersCopy).length == 1) filtersCopy = undefined;
                else delete filtersCopy[name];
            }

            let urlParams: string = "";

            urlParams = H.generateUrlParams({
                rows: paginationMeta?.rows,
                multiSortMeta: mapMultiSortMeta(multiSortMetaCopy),
                filters: filtersCopy,
            });
            onReload && onReload(urlParams); // triggers dataReload
            onParamsChange && onParamsChange({ ...params, filters: filtersCopy });

            return { ...prev, filters: filtersCopy };
        });
    };
    // [params.filters, params.multiSortMeta, paginationMeta.rows, JSON.stringify(higherDependencyId), ...refreshCallBack]

    const handleDateFilterChange = useCallback(
        (input: I.IPrimeFilterInput) => {
            let { name, value } = input;

            if (name.includes(".")) name = H.convertDotsToUnderscores(name);
            setParams({ ...params, filters: { ...params?.filters, [name]: value } });
        },
        [params.filters, params.multiSortMeta, JSON.stringify(higherDependencyId), ...refreshCallBack]
    );

    const handleCalendarClose = useCallback(
        (name: string) => {
            let { filters, multiSortMeta } = params;
            if (!filters || !filters[name] || !paginationMeta) return;

            const { page, rows } = paginationMeta;

            const urlParams = H.generateUrlParams({ page, rows, multiSortMeta: mapMultiSortMeta(multiSortMeta), filters });
            onReload && onReload(urlParams); // triggers dataReload
            onParamsChange && onParamsChange(params);
        },
        [
            params.filters,
            params.multiSortMeta,
            paginationMeta.page,
            paginationMeta.rows,
            JSON.stringify(higherDependencyId),
            ...refreshCallBack,
        ]
    );

    const handleClearDateFilter = useCallback(
        (name: string) => {
            setParams((prev) => {
                const { page, rows } = paginationMeta;
                let filtersCopy = deepCopy(prev.filters);
                const multiSortMetaCopy = deepCopy(prev.multiSortMeta);

                if (!paginationMeta || !filtersCopy) return prev;
                if (name.includes(".")) name = H.convertDotsToUnderscores(name);

                if (Object.keys(filtersCopy).length == 1 && filtersCopy?.[name]) filtersCopy = undefined;
                else delete filtersCopy[name];

                const urlParams = H.generateUrlParams({
                    page,
                    rows,
                    multiSortMeta: mapMultiSortMeta(multiSortMetaCopy),
                    filters: filtersCopy,
                });

                onReload && onReload(urlParams); // triggers dataReload
                onParamsChange && onParamsChange({ ...params, filters: filtersCopy });

                return { ...prev, filters: filtersCopy };
            });
        },
        [
            params.filters,
            params.multiSortMeta,
            paginationMeta.page,
            paginationMeta.rows,
            JSON.stringify(higherDependencyId),
            ...refreshCallBack,
        ]
    );

    const handleEditSubmit = async (submitEvent: I.EditSubmitEventType) =>
        applySubmit({
            submitEvent,
            firstPartEditUrl,
            customHandleEditSubmit,
            extraEditParams,
            params,
            setData,
            hideSuccessToast,
            idSelector,
            onEdit,
            onReload,
            handleCustomResponse,
        });

    const handleAdditionalColumnEditSubmit = async (submitEvent: I.EditSubmitEventType) => {
        applySubmit({
            submitEvent,
            firstPartEditUrl,
            customHandleEditSubmit,
            extraEditParams,
            params,
            setData,
            hideSuccessToast,
            isAdditionalColumn: true,
            idSelector,
            meta,
            onEdit,
            onReload,
            handleCustomResponse,
        });
    };

    const handleFocusSelectRecord = (row) => {
        onSelection([row[idSelector]]);
    };

    const handleHideColumns = (newVisibleColumns: I.IGeneratedPrimeTableColumn[], allHiddenColumns: I.IGeneratedPrimeTableColumn[]) => {
        const mappedColumns = H.mapColumnsWidth(newVisibleColumns, tableContainerRef);
        setGeneratedColumns({ ...generatedColumns, visible: mappedColumns });

        if (!dynamicColumns) handleSaveTableConfig({ type: "columns", payload: { columns: mappedColumns } }); // saves columns change 1in config
        if (H.shouldClearParamsAndRefresh(allHiddenColumns, params)) {
            const { page, rows } = paginationMeta;
            const urlParams = H.generateUrlParams({ page, rows });

            setParams({ filters: undefined, multiSortMeta: undefined });
            onReload && onReload(urlParams); // triggers dataReload
        }
    };

    const handleGroupBy = (column: I.IGeneratedPrimeTableColumn | undefined) => {
        let multiSortMeta: I.DataTableMultiSortMetaType = [];

        if (column) multiSortMeta = [{ header: column.header, field: column.field, order: 1, groupByThisField: true }];
        handleSort({ multiSortMeta }, true);
    };

    const handleRestoreColumns = async () => {
        if (!(await sweetConfirm("Restore default columns", "This operation is not reversible."))) return;

        const additionalColumns: I.IPrimeTableColumn[] = await H.mapToAdditionalColumns(meta?.ExtraFields, additionalColumnsEnabled);

        const _columns = H.mapColumnsOnInit(tableContainerRef, [...columns, ...additionalColumns], t, minimumColWidth);
        handleTableConfig(_columns);

        setGeneratedColumns({ ...generatedColumns, visible: _columns, default: _columns });

        !dynamicColumns && handleSaveTableConfig({ type: "columns", payload: { columns: _columns } }); // saves columns change  in config
    };

    const handleToggleFilters = () => {
        const _filterEnabled = !tableConfig.filtersEnabled;
        const tableHeight = tableConfig.tableHeight;

        let filterHeight: number = parseInt(H.getCssVariable(tableContainerRef, "--header-height"));
        const newTableHeight: number = _filterEnabled ? tableHeight - filterHeight : tableHeight + filterHeight;

        H.setCssVariable(tableContainerRef, "--table-min-height", `${newTableHeight}px`);
        handleScrollConfig("vertical", newTableHeight, _filterEnabled);
        setTableConfig({ ...tableConfig, tableHeight: newTableHeight, filtersEnabled: _filterEnabled });

        !dynamicColumns && handleSaveTableConfig({ type: "filterEnabled", payload: { filterEnabled: _filterEnabled } }); // saves filterEnabled change in config
    };

    const handleFreezeColumn = (frozenColumn?: I.IGeneratedPrimeTableColumn, originalPosition?: number) => {
        setTableConfig({
            ...tableConfig,
            frozenColumn:
                !!frozenColumn && originalPosition != undefined
                    ? {
                          field: frozenColumn.field,
                          width: H.getFrozenColWidth(frozenColumn),
                          originalPosition: originalPosition,
                      }
                    : undefined,
        });
    };

    const handleColumnResizeEnd = useCallback(
        (event: DataTableColumnResizeEndParams) => {
            // True if column that is resized is also frozen
            if (tableConfig.frozenColumn && tableConfig.frozenColumn.field == event.column.field) {
                const frozenColumn = { ...tableConfig.frozenColumn, width: `${parseInt(tableConfig.frozenColumn?.width) + event.delta}px` };
                setTableConfig({ ...tableConfig, frozenColumn: frozenColumn });
            } else {
                const resizedColumns = H.handleColumnResize(generatedColumns.visible, event, tableContainerRef, tableConfig.frozenColumn);
                !dynamicColumns && handleSaveTableConfig({ type: "columns", payload: { columns: resizedColumns } }); // saves columns change in config
                setGeneratedColumns({ ...generatedColumns, visible: resizedColumns });
            }
        },
        [JSON.stringify(generatedColumns), tableConfig]
    );

    const handleColReorder = (event: DataTableColReorderParams) => {
        const reorderedColumns = H.getReorderedColumns(event, generatedColumns.visible);
        !dynamicColumns && handleSaveTableConfig({ type: "columns", payload: { columns: reorderedColumns } }); // saves columns change in config

        setGeneratedColumns({ ...generatedColumns, visible: reorderedColumns });
    };

    const handleAdditionalColumns = async (event: I.HandleAdditionalColumnsEvent) => {
        // maps old visible columns width back to number type, without px unit at the and, thanks to this, user column width will not be lost while generating new columns
        let newColumns: I.IPrimeTableColumn[] = generatedColumns.visible.map((col) => ({ ...col, width: parseFloat(col.width) }));

        switch (event.action) {
            case I.ADDITIONAL_COLUMNS_EVENT_ACTION.ADD:
                if (!event.payload.selectedContextColumnField) {
                    newColumns.push(event.payload.column);
                } else {
                    const newColumnAddIndex: number =
                        newColumns.findIndex((col) => col.field === event.payload.selectedContextColumnField) + 1;
                    newColumns.splice(newColumnAddIndex, 0, event.payload.column);
                }

                break;
            case I.ADDITIONAL_COLUMNS_EVENT_ACTION.EDIT:
                const editColumnIndex = newColumns.findIndex((col) => col.field === event.payload.column.field);

                if (editColumnIndex !== -1) newColumns[editColumnIndex] = event.payload.column;
                break;
            case I.ADDITIONAL_COLUMNS_EVENT_ACTION.DELETE:
                newColumns = newColumns.filter((col) => col.field != event.payload.extraField);
                break;
        }

        // generates new columns(but if user changed width to some columns, new width will not be changed)
        const _columns = H.mapColumnsOnInit(tableContainerRef, newColumns, t, minimumColWidth);

        // refreshing table config with new columns
        handleTableConfig(_columns);

        // saving new columns in state
        setGeneratedColumns({ ...generatedColumns, visible: _columns, default: _columns });

        // saving new columns in db
        !dynamicColumns && handleSaveTableConfig({ type: "columns", payload: { columns: _columns } });

        // refreshes whole data, this refreshes meta data and our additional columns (extra fields), which we need for context menu to work properly
        onReload && onReload(undefined, true);
    };

    const handleAdvanceFilterReload = (filters: I.IPrimeTableFilters | undefined) => {
        let urlParams: string = H.generateUrlParams({
            rows: paginationMeta?.rows,
            multiSortMeta: mapMultiSortMeta(params.multiSortMeta),
            filters: filters,
        });

        setParams({ ...params, filters });
        onReload && onReload(urlParams); // triggers dataReload
    };

    const handleSelectedContextRecord = (e) => {
        if (selection?.length != 1 || e.value?.[idSelector] != selection[0]?.[idSelector])
            H.handleSelection(e, dataSet, selection, onSelection, idSelector);
    };

    const handleAuditData = () =>
        meta?.ModelInfo?.app && meta?.ModelInfo?.model ? { appLabel: meta?.ModelInfo?.app, model: meta?.ModelInfo?.model } : undefined;

    return (
        <AdvancedRemarksContextProvider>
            <>
                {(children || advancedFiltersConfig) && (
                    <div className={classnames("primetable-btn-container", btnContainerClassName)}>
                        <ResponsiveButtonsContainer>
                            {children}

                            <AdvanceFiltersWrapper
                                onReload={handleAdvanceFilterReload}
                                tableFilters={params.filters}
                                advancedFiltersConfig={advancedFiltersConfig as I.IAdvancedFilterConfig[]}
                            />
                        </ResponsiveButtonsContainer>
                    </div>
                )}
                <PrimeContextMenu
                    // Props needed for context-menu
                    contextMenuRef={contextMenuRef}
                    contextMenuItems={contextMenuItems}
                    // Props that provide data
                    auditData={auditData ?? handleAuditData()}
                    selectedRecord={dataSet?.find((row) => row.id == selection?.[0])}
                    dataSet={dataSet}
                    multiSort={params.multiSortMeta?.[0]}
                    tableConfig={tableConfig}
                    meta={meta}
                    // Enabled/disabled
                    exportCSVEnabled={exportCSVEnabled && dataSet.length > 0}
                    hidingColumnEnabled={hidingColumnEnabled}
                    groupRowsEnabled={tableEnabledFunctionality.groupingEnabled}
                    filterEnabled={filterEnabled && tableConfig.contextItemFilterEnable}
                    frozenColumnEnabled={frozenColumnEnabled && scrollConfig?.horizontal}
                    translateDisabled={translateDisabled}
                    additionalColumnsEnabled={additionalColumnsEnabled}
                    // Columns
                    defaultColumns={generatedColumns.default}
                    visibleColumns={generatedColumns.visible}
                    // handlers
                    onRestoreColumns={handleRestoreColumns}
                    onHideColumns={handleHideColumns}
                    onToggleFilters={handleToggleFilters}
                    onFreezeColumn={handleFreezeColumn}
                    onGroupBy={handleGroupBy}
                    onContextColumnFieldChange={onContextColumnFieldChange}
                    onAdditionalColumns={handleAdditionalColumns}
                />
                <div ref={tableContainerRef} className={`${className} prime-table-container`}>
                    {scrollConfig != undefined && (
                        <DataTable
                            value={dataSet}
                            // Display and styles
                            className={`p-datatable-sm ${tableClassName}`}
                            showGridlines={showGridlines}
                            scrollHeight={`${tableConfig.tableHeight}px`}
                            scrollable={scrollConfig.horizontal || scrollConfig.vertical}
                            emptyMessage={!isRefreshing ? <EmptyMessage t={t} /> : <LoadingMessage />}
                            frozenWidth={tableConfig.frozenColumn?.width}
                            // Column reorder
                            reorderableColumns={reorderColumnsEnabled}
                            onColReorder={handleColReorder}
                            // Column resize
                            resizableColumns={resizableColumnsEnabled && generatedColumns.visible.length > 1}
                            columnResizeMode="expand"
                            onColumnResizeEnd={handleColumnResizeEnd}
                            // Context Menu
                            contextMenuSelection={selectedRecords?.[0]}
                            onContextMenuSelectionChange={handleSelectedContextRecord}
                            onContextMenu={(e) => contextMenuRef?.current && contextMenuRef.current.show(e.originalEvent)}
                            // Selection
                            {...(Array.isArray(selection) &&
                                onSelect && {
                                    selection: _selection,
                                    onSelectionChange: (selected) =>
                                        !isBusy && H.handleSelection(selected, dataSet, selection, onSelection, idSelector),
                                    selectionMode: selectionMode,
                                })}
                            // Server side pagination
                            {...(tableEnabledFunctionality.pagination && {
                                paginator: true,
                                first: paginationMeta.first,
                                rows: paginationMeta.rows,
                                totalRecords: paginationMeta.totalRecords,
                                rowsPerPageOptions: rowsPerPage,
                                onPage: handlePage,
                                lazy: true,
                            })}
                            // Grouping
                            {...(tableEnabledFunctionality.groupingSelected && {
                                rowGroupMode: "subheader",
                                groupField: params.multiSortMeta?.[0].field,
                                rowGroupHeaderTemplate: customRowGroupHeader
                                    ? customRowGroupHeader
                                    : (row) => (
                                          <MemoRowGroupHeader
                                              frozenColumn={!!tableConfig.frozenColumn}
                                              row={row}
                                              groupField={params.multiSortMeta?.[0]}
                                              t={t}
                                          />
                                      ),
                                rowGroupFooterTemplate: customRowGroupFooter ? customRowGroupFooter : () => <td />,
                            })}
                            // Group and sort
                            {...(tableEnabledFunctionality.sortable && {
                                onSort: handleSort,
                                removableSort: true,
                                sortMode: "multiple",
                                multiSortMeta: params?.multiSortMeta,
                            })}
                            onRowDoubleClick={(e) => handleDoubleClick && handleDoubleClick(e)}
                        >
                            {generatedColumns.visible.map((col) =>
                                H.getDynamicColumns<T>({
                                    props,
                                    column: col,
                                    args: {
                                        tableConfig,
                                        params,
                                        filterFocusedFieldName,
                                        tableContainerRef,
                                        isBusy,
                                        setFilterFocusedFieldName,
                                        handleFocusSelectRecord,
                                        handleAdditionalColumnEditSubmit,
                                        handleEditSubmit,
                                        handleFilterChange,
                                        handleClearDateFilter,
                                        handleCalendarClose,
                                        handleDateFilterChange,
                                        t,
                                    },
                                })
                            )}
                        </DataTable>
                    )}
                </div>
                <AdvancedRemarksPopup modelName={meta?.ModelInfo?.model} onReload={onReload} />
            </>
        </AdvancedRemarksContextProvider>
    );
};

const arePropsEqual = (prev, next) => {
    return (
        JSON.stringify(prev.dataSet) == JSON.stringify(next.dataSet) &&
        JSON.stringify(prev.selectedRecords) == JSON.stringify(next.selectedRecords) &&
        JSON.stringify(prev.meta) == JSON.stringify(next.meta) &&
        JSON.stringify(prev.columns) == JSON.stringify(next.columns)
    );
};

// export const PrimeDataTable = memo(PrimeDataTableBase, arePropsEqual) as typeof PrimeDataTableBase;

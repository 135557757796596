import React from "react";

import loadable from "@loadable/component";
import { Spinner } from "reactstrap";

export default function AsyncComponent(props) {
    const LoadableComponent = loadable(props.component, {
        fallback: <Fallback />,
    });
    // eslint-disable-next-line @wordpress/no-unused-vars-before-return
    // const LoadableComponent = Loadable({
    //     loader: props.component,
    //     loading: () => {
    //         return (
    //             <Spinner color="secondary" type="grow">
    //                 Loading...
    //             </Spinner>
    //         );
    //     },
    // });

    return <LoadableComponent />;
}

const Fallback = () => {
    return (
        <Spinner color="secondary" type="grow">
            Loading...
        </Spinner>
    );
};

import axios from "axios";

const MEDIA_URL = "/media/";

export const downloadFile = (resp) => {
    const fileBaseURL: string = axios.defaults.baseURL?.replace(/\/api$/, "") as string;
    if (resp && resp?.data && resp.data?.file && resp.data?.name) {
        downloadOneFile(fileBaseURL + (resp.data.file.includes("/media/") ? "" : MEDIA_URL) + resp.data.file, resp.data.name);
    } else if (resp && resp?.data && Array.isArray(resp?.data.data)) {
        resp.data.data.forEach(async (fileObj) => {
            const fileUrl = fileObj.file.getAfter(fileBaseURL as string);

            downloadOneFile(fileBaseURL + (fileUrl.includes("/media/") ? "" : MEDIA_URL) + fileUrl, fileObj.name);
        });
    }
};

const downloadOneFile = async (fetchUrl, name) => {
    const response = await axios.get(fetchUrl, {
        responseType: "blob",
    });

    const url = window.URL.createObjectURL(response.data);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
};

// internal
import React from "react"

// external
import PageWrap from "../../../components/page-wrap"
import PageContent from "../../../components/page-content"
import AsyncComponent from "../../../components/async-component"
import { updateBreadcrumb } from "../../../utils/breadcrumbs"
import { QueryTemplateContextProvider } from "./context/query-template-context"

class AdvancedQueries extends React.Component {
    
    componentDidMount = updateBreadcrumb
    
    render() {
        return (
            <PageWrap>
                <PageContent>
                    <QueryTemplateContextProvider>
                        <AsyncComponent component={() => import("./content")} />
                    </QueryTemplateContextProvider>
                </PageContent>
            </PageWrap>
        )
    }
}

export default AdvancedQueries

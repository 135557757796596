import React, { createContext, useContext, useState } from "react";

import { ISelectedNodes } from "../../../../components/prime-tree/interfaces/tree-interfaces";
import { queryTemplateUrls } from "../utils/query-template-urls";
import { useFormData } from "../../../../hooks/useFormData";
import { IRelationObj } from "../interfaces/interfaces";

export const QueryTemplateContext = createContext<any>(null);

export const QueryTemplateContextProvider = ({ children }) => {
    const [selectedQueryTemplate, setSelectedQueryTemplate] = useState<number | undefined>(undefined);
    const [selectedRelations, setSelectedRelations] = useState<ISelectedNodes>({});

    const { formData: selectedRelationObj } = useFormData<IRelationObj>({
        url: Object.keys(selectedRelations).length
            ? queryTemplateUrls.relations.main(parseInt(Object.keys(selectedRelations)[0]))
            : undefined,
        triggerValues: [selectedRelations],
    });

    return (
        <QueryTemplateContext.Provider
            value={{ selectedQueryTemplate, setSelectedQueryTemplate, selectedRelations, setSelectedRelations, selectedRelationObj }}
        >
            {children}
        </QueryTemplateContext.Provider>
    );
};

export const useQueryTemplate = (): IUseQueryTemplate => {
    const { selectedQueryTemplate, setSelectedQueryTemplate, selectedRelations, setSelectedRelations, selectedRelationObj } =
        useContext(QueryTemplateContext);

    return { selectedQueryTemplate, setSelectedQueryTemplate, selectedRelations, setSelectedRelations, selectedRelationObj };
};

interface IUseQueryTemplate {
    selectedQueryTemplate: number | undefined;
    setSelectedQueryTemplate: (arg: number | undefined) => void;
    selectedRelations: ISelectedNodes;
    setSelectedRelations: (arg: ISelectedNodes) => void;
    selectedRelationObj: IRelationObj;
}

import React, { createContext, useContext, useState } from "react";

import { ISelectedNodes } from "../../../../components/prime-tree/interfaces/tree-interfaces";
import { useFormData } from "../../../../hooks/useFormData";
import { ILocation } from "../interfaces/interfaces";
import { locationUrls } from "../utils/locationUrls";

export const LocationContext = createContext<any>(null);

export const LocationContextProvider = ({ children }) => {
    const [selectedLocations, setSelectedLocations] = useState<ISelectedNodes>({});

    const locationIds = Object.keys(selectedLocations);

    const { formData: selectedLocationObj, refreshFormData: refreshLocationObject } = useFormData<ILocation>({
        url: locationIds.length == 1 ? locationUrls.main(parseInt(locationIds[0])) : undefined,
        triggerValues: [locationIds.toString()],
    });

    return (
        <LocationContext.Provider value={{ selectedLocations, setSelectedLocations, selectedLocationObj, refreshLocationObject }}>
            {children}
        </LocationContext.Provider>
    );
};

export const useLocation = (): IUseLocation => {
    const { selectedLocations, setSelectedLocations, selectedLocationObj, refreshLocationObject } = useContext(LocationContext);

    return { selectedLocations, setSelectedLocations, selectedLocationObj, refreshLocationObject };
};

interface IUseLocation {
    selectedLocations: ISelectedNodes;
    setSelectedLocations: (arg: ISelectedNodes) => void;
    selectedLocationObj: ILocation | undefined;
    refreshLocationObject: () => void;
}

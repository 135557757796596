import React, { useEffect, useRef, useState } from "react";

import { withTranslation } from "react-i18next";

import NoAccessTitle from "../no-access-title/no-access-title";
import { TabContextMenu } from "./context-menu/context-menu";
import hasPermission from "../../utils/hasPermissions";
import Tabs from "../tabs";

import "./custom-tabs.scss";

interface ITab {
    name: string;
    hide?: boolean;
    permCode?: string;
    tabContent: any;
}

interface ISingleCustomTabs {
    tab: ITab;
    className?: string;
    notTranslated?: boolean;
    t: any;
    refWrapper?: any;
    disabled?: boolean;
    contentClassName?: string;
    disableFullscreen?: boolean;
}

const SingleCustomTab = (props: ISingleCustomTabs) => {
    let { tab, t, className, notTranslated, refWrapper, contentClassName, disableFullscreen = false } = props;

    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

    // Code to display context menu START
    const [contextMenuOpen, setContextMenuOpen] = useState<boolean | undefined>(false);
    const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

    const tabRef = useRef<any>(null);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (tabRef.current && !tabRef.current.contains(e.target)) {
                setContextMenuOpen(false);
            }
        };

        const eventTypes = ["contextmenu", "click"];
        eventTypes.forEach((eventType) => {
            document.addEventListener(eventType, handleOutsideClick);
        });

        return () => {
            eventTypes.forEach((eventType) => {
                document.removeEventListener(eventType, handleOutsideClick);
            });
        };
    }, []);

    const handleTabContextMenu = (e) => {
        e.preventDefault();
        const position = e.target.getBoundingClientRect();
        setPosition({ x: e.pageX - position.x, y: e.pageY - position.y });
        setContextMenuOpen(true);
    };

    // Code to display context menu END
    const handleFullScreen = () => setIsFullscreen((prev) => !prev);

    if (!!tab.hide) return <></>;
    if (tab.permCode && !hasPermission(tab.permCode)) return <NoAccessTitle />;

    return (
        <div className={`tabs-container ${className ? className : ""} ${isFullscreen ? "fullscreen" : ""}`}>
            <div className="tabs-nav">
                <div ref={tabRef}>
                    <Tabs gtPills>
                        <div>
                            {contextMenuOpen && (
                                <TabContextMenu
                                    isFullscreen={isFullscreen}
                                    onFullScreen={!disableFullscreen && handleFullScreen}
                                    setContextMenuOpen={setContextMenuOpen}
                                    permCode={tab.permCode}
                                    position={position}
                                />
                            )}
                            <Tabs.NavItem
                                key={`tabs-nav-${tab.name}`}
                                isActive
                                disabled={props.disabled}
                                onDoubleClick={!disableFullscreen && handleFullScreen}
                                onContextMenu={(e) => handleTabContextMenu(e)}
                            >
                                {notTranslated ? tab.name : t(tab.name)}
                            </Tabs.NavItem>
                        </div>
                    </Tabs>
                </div>
                <div className="tabs-filler" />
            </div>
            <div className="tabs-wrapper" ref={refWrapper}>
                <Tabs.Content activeTab={tab.name} className={`tabs-content ${contentClassName ? contentClassName : ""}`}>
                    <Tabs.Pane tabId={tab.name}>{tab.tabContent}</Tabs.Pane>
                </Tabs.Content>
            </div>
        </div>
    );
};

export default withTranslation()(SingleCustomTab);

import { Tree, TreeExpandedKeysType, TreeExpandedParams, TreeSelectionModeType } from "primereact/tree";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Axios from "axios";

import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";

import { EmptyMessage } from "../prime-data-table/components/messages/empty-message";
import { PrimeTreeContextMenu } from "./components/context-menu/prime-tree-context-menu";
import { TreeFilterInput } from "./components/filter-input/filter-text-input";
import SuperscriptDisplay from "../superscript/superscript-display";
import CustomSpinner from "../custom-spinner/custom-spinner";
import { useResolved } from "../../hooks/useResolved";
import { useGetData } from "../../hooks/useGetData";
import { partialNodeToFully } from "./helpers";
import {
    ISelectedContextNodeEvent,
    MyTreeDragDropParams,
    IMenuItemWithModal,
    ISelectedNodes,
    IFilterConfig,
    IFilterEvent,
    MyTreeNode,
    FilterType,
    IFilterObj,
    IMenuItem,
} from "./interfaces/tree-interfaces";

import "./styles.scss";

type HeightType = "25" | "30" | "35" | "40" | "43" | "45" | "50" | "55" | "60" | "65" | "70" | "75" | "80" | "85" | "90" | "fit-content";

interface IPrimeTree {
    // URL PROP:
    //    - on drag and drop event, client will fire patch request on this url
    //    - if deleting is not disabled, delete request will fire on this url
    //    - while fetching data client will fire get request with url + "/tree",
    //      eq: when url="bs/materials" then the while get request url will be: "bs/materials/tree"
    //      you can disable this behavior by setting urlSuffix to false
    url?: string | undefined;
    urlSuffix?: boolean;
    additionalParams?: string;
    entityName?: string; // name of what user is adding, eq: "material"
    // REFRESHING DATA
    refreshData?: () => void; // in this props you can pass customized refreshData function that refresh tree data
    // this prop is a callback that is called once on init, it passed one argument, which is a refreshData function ,
    // from tree useGetData hook (expected use of this props, is to save the function that is passed in call back in state, and then use it when needed)
    setRefreshData?: (arg: () => void) => void;
    // SELECTION
    selectedNodes?: ISelectedNodes; // selected nodes
    onSelectedNodes?: (arg: ISelectedNodes) => void; // call back that is fired when user selects node
    selectionMode?: TreeSelectionModeType;
    selectFirst?: boolean;
    // EXPANDED
    expanded?: TreeExpandedKeysType;
    onExpanded?: (arg: TreeExpandedKeysType) => void;
    // FILTER
    searchField?: string; // this prop points to model field on backend while filtering
    filterPlaceholder?: string; // filter input placeholder
    // DISPLAY
    className?: string;
    height?: HeightType; // its a class with dynamic height variable, you pass it like prime-tree-height-<number here>, number is a css unit called "vh"
    // OTHER PROPS
    rootName?: string; // name of the virtual folder that hold all filters
    isDefaultRootNameNode?: boolean;
    parentNodeModelField?: string;
    disableDragdrop?: boolean; // if you want to disable drag and drop
    dragdropScope?: string; // this props is needed when there is more then one prime-tree rendered at the time, in this situation each of the prime-table needs unique dragdropScope props
    deleteNodeEnabled?: boolean; // if you want to disable delete node function
    nodeTemplate?: (node: any, options: any) => JSX.Element; // custom node template, it will replace default node
    mapChildNodes?: (
        nodes: any[],
        onSelectedNodes: undefined | ((arg: ISelectedNodes) => void),
        rootName: string,
        isDefaultRootNameNode: boolean
    ) => any[];
    // mapChildNodes?:  ; // callback function to map children nodes data before node generation, made to disable single nodes based on its props
    contextMenuItem?: IMenuItem[]; // array that contains context options
    contextModalItem?: IMenuItemWithModal[];
    // SELECTION PROPS
    selectPartialNodeFully?: boolean;
    propagateSelectionUp?: boolean;
    propagateSelectionDown?: boolean;
    propagateUnSelectionDown?: boolean;
    filter?: IFilterObj;
    handleSelectContextNode?: (e?: ISelectedContextNodeEvent, data?: any) => void;
    triggerValue?: any;
    canAddOneNodeToRoot?: boolean;
}

const defaultNodeTemplate = (node, options) => (
    <span className={options.className}>
        <SuperscriptDisplay value={node.label} />
    </span>
);

export const PrimeTree = (props: IPrimeTree) => {
    const {
        url,
        urlSuffix = true,
        additionalParams = "",
        refreshData = undefined,
        setRefreshData = undefined,
        selectedNodes = undefined,
        onSelectedNodes,
        selectionMode = "checkbox",
        selectFirst = true,
        expanded = undefined,
        onExpanded = undefined,
        rootName = "Main folder",
        isDefaultRootNameNode = true,
        filterPlaceholder = `Filter ${rootName}`,
        searchField = undefined,
        filter = undefined,
        nodeTemplate = defaultNodeTemplate,
        parentNodeModelField = "parent",
        disableDragdrop = false,
        dragdropScope = undefined,
        entityName: addEntityName = undefined,
        className = "",
        height = "70",
        deleteNodeEnabled = false,
        mapChildNodes = undefined,
        contextMenuItem = [],
        contextModalItem = [],
        selectPartialNodeFully = false,
        propagateSelectionUp = false,
        propagateSelectionDown = false,
        propagateUnSelectionDown = false,
        handleSelectContextNode = undefined,
        triggerValue: triggerValues = undefined,
        canAddOneNodeToRoot = false,
    } = props;
    const { t } = useTranslation();

    const contextMenuRef = useRef<any>(null);
    const treeContainerRef = useRef<any>(null);

    const [_expandedKeys, _setExpandedKeys] = useState<TreeExpandedKeysType>(isDefaultRootNameNode ? { 0: true } : {});
    const [selectedContextNodeKey, setSelectedContextNodeKey] = useState<number | undefined>(undefined);

    const [filterConfig, setFilterConfig] = useState<IFilterConfig>({
        value: "",
        type: "__icontains",
        enabled: false,
        contextItemEnabled: !!searchField,
        searchField: searchField,
    });

    const {
        data,
        setData,
        refreshData: _refreshData,
    } = useGetData<MyTreeNode>({
        url: url ? `${url}${urlSuffix ? "/tree" : ""}` : undefined,
        triggerValues: [triggerValues, filterConfig.value, filterConfig.type],
        initParams: "",
        configParams: { params: { noPagination: true } },
        additionalParams:
            filterConfig.value && filterConfig.searchField && additionalParams
                ? `?search_field=${filterConfig.searchField}${filterConfig.type}&search=${filterConfig.value}&${additionalParams}`
                : filterConfig.value && filterConfig.searchField
                ? `?search_field=${filterConfig.searchField}${filterConfig.type}&search=${filterConfig.value}`
                : additionalParams
                ? `?${additionalParams}`
                : undefined,
        mapData: (data): MyTreeNode[] =>
            mapChildNodes ? mapChildNodes(data, onSelectedNodes, rootName, isDefaultRootNameNode) : mapData(data),
    });

    const innerRefreshData = refreshData ? refreshData : _refreshData;
    const innerExpanded = expanded ? expanded : _expandedKeys;

    const resolved = useResolved(data?.data);

    useEffect(() => {
        const onInit = () => {
            setRefreshData && setRefreshData(() => _refreshData);
        };

        onInit();
    }, []);

    useEffect(() => {
        !!resolved && handleExpandSelected();
    }, [resolved]);

    useEffect(() => {
        const handleFilter = () => {
            setFilterConfig({ ...filterConfig, value: filter?.value || "", searchField: filter?.searchField || searchField });
        };

        handleFilter();
    }, [filter]);

    const mapData = (data: MyTreeNode[]): MyTreeNode[] => {
        if (selectFirst && selectedNodes && onSelectedNodes && data.length > 0 && Object.keys(selectedNodes).length == 0) {
            onSelectedNodes({ [data[0].id]: { checked: true } });
        }

        return isDefaultRootNameNode ? [{ id: 0, key: 0, label: t(rootName), children: data, selectable: false }] : data;
    };

    const handleExpandSelected = (nodes: MyTreeNode[] | undefined = data?.data) => {
        if (!nodes || !selectedNodes || Object.keys(selectedNodes).length == 0) return;

        let _selectedNodesArray: number[] = Object.keys(selectedNodes).map((stringKey) => parseInt(stringKey));
        let _expandedKeys: TreeExpandedKeysType = Object.keys(selectedNodes).includes(nodes[0].id.toString())
            ? { [nodes[0].id]: true }
            : {};

        generateExpanded(nodes, _expandedKeys, _selectedNodesArray);

        handleExpand(_expandedKeys);
    };

    const generateExpanded = (nodes: MyTreeNode[], expanded: TreeExpandedKeysType, selectedNodes: number[], parentsIds: number[] = []) => {
        nodes.forEach((node) => {
            if (node.children.length != 0) {
                generateExpanded(node.children, expanded, selectedNodes, [...parentsIds, node.id]);
            }

            if (selectedNodes.includes(node.id)) {
                parentsIds.forEach((parentId) => (expanded[parentId] = true));
            }
        });
    };

    const handleSelectedNodes = (e: any, onSelectedNodes) => {
        e.originalEvent.persist();

        if (!selectedNodes || !data?.data) return;

        let _e = e;

        if (selectionMode == "single")
            _e.value = e?.value ? { [_e?.value]: { checked: true, label: _e.originalEvent?.target?.innerText } } : {};
        else if (selectionMode == "checkbox") {
            delete _e.value[0];
            const unSelectedNodeId = e.originalEvent._targetInst._debugOwner.key;

            if (
                propagateUnSelectionDown &&
                !propagateSelectionDown &&
                unSelectedNodeId &&
                Object.keys(_e.value).length < Object.keys(selectedNodes).length
            ) {
                const value = getUnSelectedNodes(data?.data, parseInt(unSelectedNodeId));
                _e.value = value;
            }
        }

        if (selectPartialNodeFully) _e.value = partialNodeToFully(_e.value);
        onSelectedNodes(_e.value);
    };
    const getUnSelectedNodes = (nodes: MyTreeNode[], unSelectedNodeId: number) => {
        let res = {};

        nodes.forEach((node) => {
            if (node.id == unSelectedNodeId) {
                const nodesToUnSelect = [node.id, ...getAllNodeId(node.children)];

                let unSelectedNodes: ISelectedNodes = JSON.parse(JSON.stringify(selectedNodes));
                nodesToUnSelect.forEach((nodeId) => delete unSelectedNodes[nodeId]);

                res = unSelectedNodes;
            } else if (node.children.length > 0) {
                const _res = getUnSelectedNodes(node.children, unSelectedNodeId);
                res = { ...res, ..._res };
            }
        });

        return res;
    };

    const getAllNodeId = (nodes: MyTreeNode[]): number[] => {
        let ids: number[] = [];

        nodes.forEach((node) => {
            if (node.children.length > 0) ids.push(node.id, ...getAllNodeId(node.children));
            else ids.push(node.id);
        });

        return ids;
    };

    const handleToggleNodes = (e?: TreeExpandedParams) => {
        if (!e) handleExpand({ 0: true });
        else handleExpand(e.value);
    };

    const handleExpand = (expanded) => {
        onExpanded ? onExpanded(expanded) : _setExpandedKeys(expanded);
    };

    const handleSelectedContextNodeKey = (e?: ISelectedContextNodeEvent) => {
        handleSelectContextNode && handleSelectContextNode(e, data?.data);

        if (!e) setSelectedContextNodeKey(undefined);
        else setSelectedContextNodeKey(e.value);
    };

    const handleDragDrop = async (e: MyTreeDragDropParams) => {
        const { dragNode, dropNode } = e;

        try {
            await Axios.patch(`${url}/${dragNode.id}`, { [parentNodeModelField]: dropNode.id == 0 ? null : dropNode.id });

            innerRefreshData();
            setData({ ...data, data: e.value as any });
        } catch {}
    };

    const handleToggleFilter = () => setFilterConfig((prevState) => ({ ...prevState, enabled: !prevState.enabled }));

    const handleFilterChange = useCallback(
        (e: IFilterEvent, filterType: FilterType) => {
            setFilterConfig({ ...filterConfig, value: e.value, type: filterType, searchField: searchField });
        },
        [filterConfig]
    );

    if (!resolved) return <CustomSpinner />;

    return (
        <div
            className={`prime-tree-container ${
                filterConfig.enabled ? "filter-enabled" : "filter-disabled"
            } ${className} prime-tree-height-${height} `}
            ref={treeContainerRef}
        >
            {filterConfig.enabled && (
                <TreeFilterInput
                    placeholder={filterPlaceholder}
                    onFilterChange={handleFilterChange}
                    tableRef={treeContainerRef}
                    filter={{
                        filterType: filterConfig.type,
                        value: filterConfig.value,
                    }}
                />
            )}
            {url && data?.data && (data.data.length > 1 || data.data[0]?.children) ? (
                <>
                    <PrimeTreeContextMenu
                        disableAdd={isDefaultRootNameNode && canAddOneNodeToRoot ? data.data[0].children.length != 0 : false}
                        contextMenuRef={contextMenuRef}
                        //carrying information
                        url={url}
                        filterConfig={filterConfig}
                        selectedNodes={selectedNodes}
                        expandedKeys={innerExpanded}
                        selectedContextNodeKey={selectedContextNodeKey}
                        //handlers
                        onSelectedNodes={onSelectedNodes}
                        handleToggleFilter={handleToggleFilter}
                        handleToggleNodes={handleToggleNodes}
                        handleSelectedContextNodeKey={handleSelectedContextNodeKey}
                        handleExpandSelected={handleExpandSelected}
                        //dynamic items
                        contextMenuItem={contextMenuItem}
                        //dynamic modals && delete node
                        contextModalItem={contextModalItem}
                        selectionMode={selectionMode}
                        refreshData={innerRefreshData}
                        entityName={addEntityName}
                        deleteNodeEnabled={deleteNodeEnabled}
                    />
                    <Tree
                        value={data?.data}
                        //selection
                        {...(!!onSelectedNodes &&
                            !!selectedNodes && {
                                selectionMode: selectionMode,
                                propagateSelectionUp: selectionMode == "checkbox" && propagateSelectionUp,
                                propagateSelectionDown: selectionMode == "checkbox" && propagateSelectionDown,

                                selectionKeys:
                                    selectionMode == "single" ? parseInt(Object.keys(selectedNodes)?.[0]) : (selectedNodes as any),
                                onSelectionChange: (e: any) => handleSelectedNodes(e, onSelectedNodes),
                            })}
                        //drag and drop
                        {...(!disableDragdrop &&
                            dragdropScope && {
                                dragdropScope: dragdropScope,
                                onDragDrop: handleDragDrop,
                            })}
                        //expand/collapse
                        expandedKeys={innerExpanded}
                        onToggle={handleToggleNodes}
                        //context menu
                        contextMenuSelectionKey={selectedContextNodeKey as any}
                        onContextMenuSelectionChange={(e: any) => handleSelectedContextNodeKey(e as ISelectedContextNodeEvent)}
                        onContextMenu={(event) => contextMenuRef?.current?.show(event.originalEvent)}
                        //node template
                        nodeTemplate={nodeTemplate}
                    />
                </>
            ) : (
                <div className="empty-tree-placeholder">
                    <EmptyMessage t={t} />
                </div>
            )}
        </div>
    );
};

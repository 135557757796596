import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { IEditTextareaInputProps } from "../interfaces/edit-interfaces";
import { getNestedObjValue } from "../helpers/primeHelpers";
import { ReqParamsHOC } from "../helpers/req-params-hoc";
import { moveKeyPress } from "../helpers/moveKeyPress";
import { onPressType } from "../helpers/goToNextInput";
import { memoEditInput } from "./memoEditInput";

// table edit component, you send it in the column as editBody prop
// editBody: (inputData: IPrimeEditData<ProperInterface>) => (<EditTextareaInput inputData={inputData} />),
const EditTextareaInputBase = (props: IEditTextareaInputProps) => {
    const {
        inputData: { row, fieldName },
        inputEnabled = true,
        value = undefined,
        className = "",
        style = {},
    } = props;

    if (inputEnabled) return <EnabledInput {...props} />;

    return (
        <textarea
            disabled
            id="input-placeholder"
            key={`prime-input-textarea-${fieldName}-${row.id}`}
            rows={1}
            style={style}
            value={value ? value : getNestedObjValue(row, fieldName.split("."))}
            className={`input-value prime-edit-textarea-input ${className}} `}
        />
    );
};

export const EditTextareaInput = memoEditInput(EditTextareaInputBase);

const EnabledInput = (props: IEditTextareaInputProps) => {
    const { t } = useTranslation();

    const [inputValue, setInputValue] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const [hasKeyBeenPressed, setHasKeyBeenPressed] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const {
        //base
        value = undefined,
        name = undefined,
        shouldDataSetRefresh: refresh = false,
        extraEditParams = {},
        editUrl = undefined,
        secondPartEditUrl = undefined,
        className = "",
        clientSideEdit = false,
        style = {},
        editParamsBeforeChange = undefined,
        extraParametersToSet = undefined,
        //common
        placeholder = "Enter value...",
        nextRecordOnEnter = true,
        //unique
        customMoreModal = undefined,
    } = props;
    const { row, fieldName, handleEditSubmit, handleFocusSelectRecord, isBusy, permCode = "", rowIndex } = props.inputData;

    const formRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const keyPressedRef = useRef<string>("");

    const _name = name || fieldName;

    useEffect(() => {
        if (value != undefined) setInputValue(value);
        else {
            const _value = row[fieldName];
            setInputValue(!!_value ? _value : _value === 0 ? "0" : "");
        }

        error && setError(false);
    }, [row[fieldName]]);

    const blockKeySpam = () => {
        setHasKeyBeenPressed(true);
        setTimeout(() => {
            setHasKeyBeenPressed(false);
        }, 1000);
    };

    const handleKeyPress = async (e) => {
        if (hasKeyBeenPressed) return;

        switch (e.nativeEvent.keyCode) {
            case 13: // on press enter
                keyPressedRef.current = e.key;
                e.preventDefault();
                blockKeySpam();
                await handleOnBlur({ target: { name: _name, value: inputValue } }, "enter");
                break;

            case 9: // on press tab
                keyPressedRef.current = e.key;
                blockKeySpam();
                await handleOnBlur({ target: { name: _name, value: inputValue } }, "tab");
                break;
        }
    };

    const handleOnBlur = async (e, onPress: onPressType | undefined = undefined) => {
        let _e = { target: { name: e.target.name, value: e.target.value, oldValue: value || row[fieldName] } };
        const oldValue = value || row[fieldName];

        // Old value comparison has to be done here because it has to be done before req params check
        if (oldValue == _e.target.value || ((oldValue == null || oldValue == undefined) && !_e.target.value))
            return onPress == "enter" && moveKeyPress(`prime-input-text-${fieldName}-`, rowIndex);

        if (!permCode) handleSubmit(e, undefined);

        formRef.current && formRef.current.click();
    };

    const onChange = (e) => {
        setInputValue(e.target.value);
        error && setError(false);
    };

    const handleChangeDictValue = async (value) => {
        const _e = { target: { name: _name, value: value } };
        const params = editParamsBeforeChange ? editParamsBeforeChange(extraEditParams, inputRef.current) : extraEditParams;

        const response = await handleEditSubmit({
            row: row,
            e: _e,
            secondPartEditUrl,
            extraColumnEditParams: params,
            refresh: refresh,
            clientSideEdit: clientSideEdit,
            extraParametersToSet: extraParametersToSet,
            editUrl,
        });

        if (response?.status?.toString()[0] == "2" || response?.response?.status == undefined) {
            return;
        } else setError(true);
    };

    const handleClose = () => setInputValue(value || row[fieldName]);

    const handleSubmit = async (e: SyntheticEvent, reqParams?: IReqParams) => {
        const _e = { target: { name: _name, value: inputRef.current?.value } };
        const params = editParamsBeforeChange ? editParamsBeforeChange(extraEditParams, inputRef.current) : extraEditParams;

        await handleEditSubmit({
            row: row,
            e: _e,
            secondPartEditUrl,
            extraColumnEditParams: params,
            refresh: refresh,
            clientSideEdit: clientSideEdit,
            extraParametersToSet: extraParametersToSet,
            editUrl,
            reqParams: reqParams,
        });

        keyPressedRef.current == "Enter" && moveKeyPress(`prime-input-text-${fieldName}-`, rowIndex);
    };

    return (
        <ReqParamsHOC onSubmit={handleSubmit} permCode={permCode} formRef={formRef} onClose={handleClose}>
            <textarea
                disabled={isBusy}
                key={`prime-input-textarea-${fieldName}-${row.id}`}
                id={`prime-input-text-${fieldName}-${rowIndex}`}
                rows={1}
                style={style}
                value={inputValue}
                name={_name}
                onChange={onChange}
                onBlur={handleOnBlur}
                onFocus={() => handleFocusSelectRecord(row)}
                onKeyDown={(e) => nextRecordOnEnter && handleKeyPress(e)}
                className={`input-value prime-edit-textarea-input ${className} ${error ? "error" : ""} `}
                placeholder={t(placeholder)}
                ref={inputRef}
            />
            <div style={{ position: "relative", width: "0px", overflow: "visible" }}>
                {customMoreModal && (
                    <>
                        <span onClick={() => setModalOpen(true)} className="pi pi-ellipsis-h prime-edit-more-icon textarea" />
                        {modalOpen &&
                            customMoreModal(
                                () => setModalOpen(false),
                                (value) => handleChangeDictValue(value),
                                inputValue
                            )}
                    </>
                )}
            </div>
        </ReqParamsHOC>
    );
};

import React, { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { IDemandFormData, IOfferFormData, ITableDemandsList } from "../interfaces/interface";

import { useFormData } from "../../../../hooks/useFormData";

export const DemandsListContext = createContext<any>(null);

const urgent: ILabelValue[] = [
    { label: "Urgent", value: "True" },
    { label: "Not urgent", value: "False" },
];

export const DemandsListProvider = ({ children }) => {
    const { t } = useTranslation();
    const [selectedDemandsList, setSelectedDemandsList] = useState<ITableDemandsList[]>([]);
    const [refreshDemandData, setRefreshDemandData] = useState<Function | undefined>(undefined);

    const { formData } = useFormData<IDemandFormData>({
        url: `${window.location.pathname}/app-form-data`,
        mapFormData: (data): IDemandFormData => ({
            ...data,
            urgent: urgent.map((o) => ({ ...o, label: t(o.label) })),
            months: data.months.map((o) => ({ ...o, label: t(o.label) })),
            statuses: data.statuses.map((o) => ({ ...o, label: t(o.label) })),
        }),
    });

    return (
        <DemandsListContext.Provider
            value={{
                selectedDemandsList,
                setSelectedDemandsList,
                formData,
                refreshDemandData,
                setRefreshDemandData,
                offersFormData: {
                    suppliers: formData?.suppliers,
                    packagings: formData?.packagings,
                    priceCurrencyOptions: formData?.priceCurrencyOptions,
                },
                editable: selectedDemandsList[0]?.workflowStep?.editableData,
            }}
        >
            {children}
        </DemandsListContext.Provider>
    );
};

export const useDemandsList = (): IDemandsList => {
    const { selectedDemandsList, setSelectedDemandsList, formData, refreshDemandData, setRefreshDemandData, offersFormData, editable } =
        useContext(DemandsListContext);

    return { selectedDemandsList, setSelectedDemandsList, formData, refreshDemandData, setRefreshDemandData, offersFormData, editable };
};

interface IDemandsList {
    selectedDemandsList: ITableDemandsList[];
    setSelectedDemandsList: (arg: ITableDemandsList[]) => void;
    formData: IDemandFormData;
    offersFormData: IOfferFormData;
    refreshDemandData?: Function;
    setRefreshDemandData: Function;
    editable: boolean;
}

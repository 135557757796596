export type EditTabKeysType =
    | "samples"
    | "samplingSites"
    | "additionalInformation"
    | "patient"
    | "formulations"
    | "attachmentsForOrder"
    | "additionalTasks";
export type EditTabValuesType =
    | "Samples"
    | "Sampling sites"
    | "Additional information"
    | "Patient"
    | "Formulations"
    | "Attachments for order"
    | "Additional tasks";

export type EditTabs = {
    [key in EditTabKeysType]: EditTabValuesType;
};

export const editTabs: EditTabs = {
    samples: "Samples",
    samplingSites: "Sampling sites",
    additionalInformation: "Additional information",
    patient: "Patient",
    formulations: "Formulations",
    attachmentsForOrder: "Attachments for order",
    additionalTasks: "Additional tasks",
};

import React from "react";

import { useTranslation } from "react-i18next";

import ResponsiveTabsWrapper from "./responsiveTabsWrapper";
import { TabContextMenu } from "../context-menu/context-menu";
import Tabs from "../../tabs";

export const IntersectionTabs = ({
    validTabs,
    contextMenuOpen,
    isFullscreen,
    handleFullScreen,
    setContextMenuOpen,
    disableFullscreen,
    position,
    selectedTab,
    disabled,
    handleTabContextMenu,
    notTranslated,
    handleClick,
}) => {
    const { t } = useTranslation();
    return (
        <ResponsiveTabsWrapper selectedTab={selectedTab} validTabs={validTabs}>
            {(validTabs as any).map((tab, i) => (
                <div key={`tabs-nav-${tab?.name}-${i}`} data-targetid={tab?.name} data-isactive={selectedTab == tab?.name}>
                    {contextMenuOpen == i && (
                        <TabContextMenu
                            isFullscreen={isFullscreen}
                            onFullScreen={!disableFullscreen && handleFullScreen}
                            setContextMenuOpen={setContextMenuOpen}
                            permCode={tab?.permCode}
                            position={position}
                        />
                    )}
                    <Tabs.NavItem
                        isActive={selectedTab == tab?.name}
                        onClick={() => handleClick(tab)}
                        onDoubleClick={!disableFullscreen && handleFullScreen}
                        disabled={tab?.disabled}
                        className={disabled && selectedTab == tab?.name ? "tabs-nav-disabled" : ""}
                        onContextMenu={(e) => handleTabContextMenu(e, i)}
                        cyId={tab?.cyId}
                        permCode={tab?.permCode}
                    >
                        {notTranslated ? tab?.name : t(tab?.name)}
                    </Tabs.NavItem>
                </div>
            ))}
        </ResponsiveTabsWrapper>
    );
};

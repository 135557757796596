export const getStatusDisplay = (s: string) => {
    s = s.replaceAll(" ", "-").toLowerCase();
    return PRETTY_CELL_CONFIG[s]?.label || s.charAt(0).toUpperCase() + s.slice(1);
};

export interface IPrettyCellConfig {
    [key: string]: {
        icon: [string, string] | string;
        label: string;
        color: string;
    };
}

// Keep `prettyCellColorsMap` and `PRETTY_CELL_CONFIG` in sync with weblab_backend/settings/helpers/icons.py

export const prettyCellColorsMap: { [key: string]: string } = {
    colorPositive: "#b1f7bf", // green
    colorNegative: "#f78483", // red
    colorWarning: "#ffd875", // yellow
    colorInfo: "#e6e6e6", //  ??
    colorSpecial: "#d5c6e4", // pink
    colorPending: "#a3f0ff", // blue
    defaultColor: "#e6e6e6", // def color info
};

export const PRETTY_CELL_CONFIG: IPrettyCellConfig = {
    // audit-specific cells, aliasing to readable tag
    "user+": { icon: ["fas", "sign-in-alt"], label: "auditApp>>Log In", color: prettyCellColorsMap.colorPositive },
    "user-": { icon: ["fas", "sign-out-alt"], label: "auditApp>>Log Out", color: prettyCellColorsMap.colorNegative },

    "+": { icon: ["fas", "plus"], label: "auditApp>>Add", color: prettyCellColorsMap.colorPositive },
    "-": { icon: ["fas", "minus"], label: "auditApp>>Delete", color: prettyCellColorsMap.colorNegative },
    "~": { icon: ["fas", "exchange-alt"], label: "auditApp>>Change", color: prettyCellColorsMap.colorWarning },
    r: { icon: ["fas", "exchange-alt"], label: "auditApp>>Read", color: prettyCellColorsMap.colorWarning },

    // statuses
    draft: { icon: ["gt", "draft"], label: "Draft", color: prettyCellColorsMap.colorInfo },
    new: { icon: ["gt", "notification_2"], label: "New", color: prettyCellColorsMap.colorInfo },
    planned: { icon: ["gt", "calendar"], label: "Planned", color: prettyCellColorsMap.colorPending },
    active: { icon: ["gt", "active"], label: "Active", color: prettyCellColorsMap.colorPositive },
    "in-usage": { icon: ["gt", "in_use"], label: "In usage", color: prettyCellColorsMap.colorPositive }, //czy nie lepiej niebieski?
    done: { icon: ["gt", "check"], label: "Done", color: prettyCellColorsMap.colorPositive },
    warn_done: { icon: ["gt", "alert"], label: "Warn Done", color: prettyCellColorsMap.colorWarning },
    warn_oos: { icon: ["gt", "alert"], label: "Warn OOS", color: prettyCellColorsMap.colorWarning },
    oos: { icon: ["gt", "negative"], label: "OOS", color: prettyCellColorsMap.colorNegative },
    cancelled: { icon: ["gt", "negative"], label: "Cancelled", color: prettyCellColorsMap.colorNegative },
    quarantine: { icon: ["gt", "virus"], label: "Quarantine", color: prettyCellColorsMap.colorWarning },
    utilized: { icon: ["gt", "recycle"], label: "Utilized", color: prettyCellColorsMap.colorPositive },
    released: { icon: ["gt", "check"], label: "Released", color: prettyCellColorsMap.colorPositive },
    received: { icon: ["gt", "receive"], label: "Received", color: prettyCellColorsMap.colorPositive },
    retired: { icon: ["fas", "ban"], label: "Retired", color: prettyCellColorsMap.colorInfo },
    "not-active": { icon: ["gt", "inactive"], label: "Not active", color: prettyCellColorsMap.colorNegative }, //czy nie lepiej szary?
    archived: { icon: ["gt", "archived"], label: "Archived", color: prettyCellColorsMap.colorInfo },
    "in-lab": { icon: ["gt", "lab_flask_2"], label: "In Lab", color: prettyCellColorsMap.colorPending },
    sampled: { icon: ["gt", "rotated_tube"], label: "Sampled", color: prettyCellColorsMap.colorPending },
    prepared: { icon: ["gt", "experiment_flask"], label: "Prepared", color: prettyCellColorsMap.colorPending },
    conducted: { icon: ["gt", "check"], label: "Conducted", color: prettyCellColorsMap.colorPositive },
    canceled: { icon: ["gt", "negative"], label: "Canceled", color: prettyCellColorsMap.colorWarning }, //czerwony?
    started: { icon: ["gt", "start"], label: "Started", color: prettyCellColorsMap.colorInfo }, // niebieski?
    added: { icon: ["gt", "add"], label: "Added", color: prettyCellColorsMap.colorInfo },
    deleted: { icon: ["gt", "delete"], label: "Deleted", color: prettyCellColorsMap.colorNegative },
    "in-use": { icon: ["gt", "in_use"], label: "In use", color: prettyCellColorsMap.colorPending },
    logged: { icon: ["gt", "login"], label: "Logged", color: prettyCellColorsMap.colorPositive },
    "in-progress": { icon: ["gt", "pending_3"], label: "In progress", color: prettyCellColorsMap.colorPending },
    internal: { icon: ["fas", "folder"], label: "Internal", color: prettyCellColorsMap.colorPending }, //nie wiem co znaczy
    external: { icon: ["fas", "folder"], label: "External", color: prettyCellColorsMap.colorPending }, //nie wiem co znaczy
    "in-service": { icon: ["gt", "service"], label: "In service", color: prettyCellColorsMap.colorPending },
    timetable: { icon: ["gt", "calendar"], label: "", color: prettyCellColorsMap.defaultColor },
    autostart: { icon: ["gt", "start"], label: "", color: prettyCellColorsMap.defaultColor },
    created: { icon: ["gt", "create"], label: "Created", color: prettyCellColorsMap.colorInfo },
    "pre-draft": { icon: ["gt", "draft"], label: "Pre draft", color: prettyCellColorsMap.colorInfo },
    awaits_approval: { icon: ["gt", "pending_3"], label: "Awaits approval", color: prettyCellColorsMap.colorPending },
    in_creation: { icon: ["fas", "wrench"], label: "In creation", color: prettyCellColorsMap.defaultColor },
    approved: { icon: ["gt", "check"], label: "_statuses>>Approved", color: prettyCellColorsMap.colorPositive },
    damaged: { icon: ["gt", "negative"], label: "Damaged", color: prettyCellColorsMap.colorNegative },
    printed: { icon: ["fas", "print"], label: "Printed", color: prettyCellColorsMap.colorInfo },
    paid: { icon: ["fas", "money-check-dollar"], label: "Paid", color: prettyCellColorsMap.colorPositive },

    //validation
    valid: { icon: ["gt", "check"], label: "_statuses>>Valid", color: prettyCellColorsMap.colorPositive },
    invalid: { icon: ["gt", "negative"], label: "Invalid", color: prettyCellColorsMap.colorWarning },

    //specifications
    production: { icon: ["gt", "production"], label: "Production", color: prettyCellColorsMap.colorWarning }, // tu też nie wiem jaki kontekst
    diagnostic: { icon: ["gt", "diagnostic"], label: "Diagnostic", color: prettyCellColorsMap.colorPending }, // tu bym dała zielony mozę ale nie wiem
    nondiagnostic: { icon: ["gt", "diagnostic"], label: "Nondiagnostic", color: prettyCellColorsMap.colorNegative }, //czy nie lepiej żółty?
    inconclusive: { icon: ["gt", "question"], label: "Inconclusive", color: prettyCellColorsMap.colorPending }, //żółty raczej

    //sample workflow statuses
    receive: { icon: ["gt", "receive"], label: "Receive", color: prettyCellColorsMap.defaultColor },
    register: { icon: ["fas", "clipboard"], label: "Register", color: prettyCellColorsMap.defaultColor },
    prepare: { icon: ["gt", "experiment_flask"], label: "Prepare", color: prettyCellColorsMap.defaultColor },
    "receive-in-lab": { icon: ["fas", "flask"], label: "Receive in lab", color: prettyCellColorsMap.defaultColor },
    results: { icon: ["fas", "square-poll-vertical"], label: "Results", color: prettyCellColorsMap.defaultColor },
    sampling: { icon: ["fas", "vials"], label: "Sampling", color: prettyCellColorsMap.defaultColor },
    edit: { icon: ["fas", "edit"], label: "Edit", color: prettyCellColorsMap.defaultColor },
    preview: { icon: ["fas", "paste"], label: "Preview", color: prettyCellColorsMap.defaultColor },

    // demands statuses
    sent_for_estimation: { icon: ["gt", "send"], label: "Sent for estimation", color: prettyCellColorsMap.colorPending },
    estimated: { icon: ["gt", "money"], label: "Estimated", color: prettyCellColorsMap.colorPositive },
    accepted_by_manager: { icon: ["gt", "check"], label: "Accepted by manager", color: prettyCellColorsMap.colorPositive },
    sent_for_dt_acceptation: { icon: ["gt", "send"], label: "Sent for DT acceptation", color: prettyCellColorsMap.colorPending },
    accepted: { icon: ["gt", "check"], label: "Accepted", color: prettyCellColorsMap.colorPositive },
    rejected: { icon: ["gt", "negative"], label: "Rejected", color: prettyCellColorsMap.colorNegative },
    correction: { icon: ["gt", "alert"], label: "Correction", color: prettyCellColorsMap.colorWarning },
    realized: { icon: ["gt", "check"], label: "Realized", color: prettyCellColorsMap.colorPositive },
    partially_realized: { icon: ["gt", "half"], label: "Partially realized", color: prettyCellColorsMap.colorPositive },
    "took-part-in": { icon: ["gt", "pending"], label: "Took part in", color: prettyCellColorsMap.colorPending },
    certified: { icon: ["gt", "certified"], label: "Certified", color: prettyCellColorsMap.colorPositive },

    //result_sheet
    finished: { icon: ["gt", "check"], label: "Finished", color: prettyCellColorsMap.colorPositive },
    activated: { icon: ["gt", "check"], label: "Activated", color: prettyCellColorsMap.colorWarning }, //tu w sumie nie wiem jaki jest kontekst, może fioletowy?
    opened: { icon: ["gt", "view"], label: "Opened", color: prettyCellColorsMap.colorPending }, //szary?

    //test reports
    pending: { icon: ["gt", "pending_3"], label: "Pending", color: prettyCellColorsMap.colorInfo }, // tu totalnie powinno być niebieskie
    signed: { icon: ["gt", "edit"], label: "Signed", color: prettyCellColorsMap.colorPending }, //a tu szary
    sent: { icon: ["gt", "send"], label: "Sent", color: prettyCellColorsMap.colorPositive },
};

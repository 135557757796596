import React, { createContext, useContext, useState } from "react";
import { ISamplingPlanTable } from "../interfaces/interfaces";

export const SamplingPlansContext = createContext<any>(null);

export const SamplingPlansContextProvider = ({ children }) => {
    const [selectedSamplingPlans, setSelectedSamplingPlans] = useState<ISamplingPlanTable[]>([]);

    return <SamplingPlansContext.Provider value={{ selectedSamplingPlans, setSelectedSamplingPlans }}>{children}</SamplingPlansContext.Provider>;
};

export const useSamplingPlans = (): IUseSamplingPlans => {
    const { selectedSamplingPlans, setSelectedSamplingPlans } = useContext(SamplingPlansContext);

    return { selectedSamplingPlans, setSelectedSamplingPlans };
};

interface IUseSamplingPlans {
    selectedSamplingPlans: ISamplingPlanTable[];
    setSelectedSamplingPlans: (arg: ISamplingPlanTable[]) => void;
}

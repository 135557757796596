import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { Form, Modal, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import Axios from "axios";

import { IMetaAppTypeTable } from "pages/BasicSettings/MetaAppTypes/interfaces/interfaces";
import { metaAppsUrls } from "components/MetaApps/utils/meta-apps-urls";
import { IReqParams } from "../../../../pages/interfaces/IReqParams";
import { IErrors } from "../../../../pages/interfaces/IErrors";
import { handleErrorDelete } from "utils/handleDeleteError";
import addToast from "utils/addToast";

import { useFormData } from "hooks";
import * as GT from "components";

interface ICreateMetaAppRecordModalProps {
    closeModal: () => void;
    selectedRecordId?: number;
    metaAppTypeName: string;
}

interface ICreateInvestigationModal {
    metaAppName?: string;
}

export const CreateMetaAppRecordModal = ({ closeModal, selectedRecordId, metaAppTypeName }: ICreateMetaAppRecordModalProps) => {
    const { t } = useTranslation();

    const { formData: metaAppType } = useFormData<IMetaAppTypeTable, IMetaAppTypeTable[]>({
        url: metaAppsUrls.metaAppType(),
        params: { params: { search_field: "name__exact", search: metaAppTypeName } },
        mapFormData: (formData) => formData[0],
    });

    const [data, setData] = useState<ICreateInvestigationModal | undefined>(undefined);
    const [errors, setErrors] = useState<IErrors>({});

    const handleCloseModal = () => closeModal();

    const handleSubmitData = async (e: SyntheticEvent, reqParams: IReqParams = {}) => {
        e.preventDefault();

        try {
            const response = await Axios.post(metaAppsUrls.metaApp(), {
                ...data,
                type: metaAppType?.id,
                qbeFilterValue: selectedRecordId,
                reqParams,
            });

            addToast({ title: `${metaAppTypeName}: ${t("Record has been added")}` });
            handleCloseModal();

            return response.status;
        } catch (e: any) {
            e.response?.status == "400" && setErrors(e.response.data);

            if (e.response?.status == "403") return e.response.data;
            else return e.response.status;
        }
    };

    const handleChangeState = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value || "" });
        errors[name] && setErrors(handleErrorDelete(errors, name));
    };

    return (
        <Modal isOpen={true} toggle={() => closeModal()} fade>
            <Form onSubmit={handleSubmitData}>
                <GT.ModalHeader closeModal={handleCloseModal} name={`${t("Add record in")}: ${metaAppTypeName}`} />
                <ModalBody>
                    <GT.CustomInput
                        errors={errors.metaAppName}
                        handleChange={handleChangeState}
                        labelText="Name"
                        type="text"
                        name="metaAppName"
                        value={data?.metaAppName || ""}
                    />
                </ModalBody>
                <GT.ModalFooter closeModal={handleCloseModal} cancelName="Cancel" okName="Save" />
            </Form>
        </Modal>
    );
};

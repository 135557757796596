import React from "react";

import axios from "axios";

import CustomModalCreatableSelect from "../custom-modal-elements/custom-modal-creatable-select";
import { IErrors } from "../../pages/interfaces/IErrors";
import { useFetchData } from "../../hooks/useFetchData";
import { useTranslation } from "react-i18next";

interface ILookupField {
    handleChange: (e: any) => void;
    errors?: IErrors;
    name: string;
    lookupName: string;
    label: string;
    value: string | undefined;
    disabled?: boolean;
    translateOptions?: boolean;
    selectProps?: any;
}

export const LookupField = ({
    handleChange,
    errors,
    name,
    lookupName,
    label,
    value,
    disabled = false,
    translateOptions = false,
    selectProps,
}: ILookupField) => {
    const { data: options, setData: setOptions } = useFetchData<ILabelValue[]>({
        url: `bs/lookups/lookup-fields/${lookupName}`,
        onSuccess: (data) => setOptions(data.map((record) => ({ ...record, label: translateOptions ? t(record.label) : record.label }))),
    });
    const { t } = useTranslation();

    const handleAddLookup = async (newValue) => {
        try {
            let response = await axios.post(`bs/lookups/lookup-fields`, {
                newLookup: { lookupName: lookupName, value: newValue, label: newValue },
            });

            handleChange({ target: { value: newValue, name: name } });
            setOptions((prev) => [
                ...(prev || []),
                {
                    label: translateOptions ? t(response.data["label"]) : response.data["label"],
                    value: response.data["value"],
                    id: response.data["id"],
                },
            ]);
        } catch (err: any) {
            console.error(err);
        }
    };

    return (
        <CustomModalCreatableSelect
            errors={errors?.[name]}
            handleChange={(e, f) => handleChange({ target: { value: e?.value === undefined ? "" : e?.value, name: f.name } })}
            options={options || []}
            name={name}
            labelName={label}
            handleCreateOption={handleAddLookup}
            value={options?.find((option) => option.label == (translateOptions ? t(value as string) : value))?.value}
            disabled={disabled}
            {...selectProps}
        />
    );
};

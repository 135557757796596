import React, { ReactNode, useState } from "react";

import CustomButton from "components/custom-button/custom-button";
import { useMountTransition } from "hooks/useMountTransition";

interface IModalWrapper {
    permCode: string;
    text?: string;
    icon?: string | { name: string[] };
    render?: any;
    disabled?: boolean;
    children?: (modalOpen: boolean, closeModal: () => void) => ReactNode;
}

export const ModalWrapper = ({ text, icon, permCode, disabled, render: ModalContent, children }: IModalWrapper) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const hasTransitionedIn = useMountTransition(modalOpen);
    return (
        <>
            <CustomButton text={text} icon={icon} onClick={() => setModalOpen(true)} permCode={permCode} disabled={disabled} />
            {(modalOpen || hasTransitionedIn) && children && children(modalOpen, () => setModalOpen(false))}
        </>
    );
};
